import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Notification } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { ContentDTO, ContentRequest } from 'campaigner-client';
import { ContentEditor } from '@/components/campaign/content-editor/ContentEditor';
import { useCreateContent } from '@/queries/content/mutations/create.content.mutation';

export function ContentCreationPage() {
  const { campaignId } = useParams<{ campaignId: string }>();
  const navigate = useNavigate();
  const { mutateAsync: createContent, isPending: isCreating } = useCreateContent(campaignId!);
  const [showSuccessBanner, setShowSuccessBanner] = React.useState(false);

  const handleSave = async (newContent: Partial<ContentDTO>) => {
    if (!newContent.title || !newContent.content || !newContent.contentCategory) {
      console.error('Please ensure that the title/content/category are filled in before saving.');
      return;
    }
    try {
      const createContentRequest: ContentRequest = {
        title: newContent.title,
        content: newContent.content,
        contentCategory: newContent.contentCategory,
        metadata: newContent.metadata || {},
      };
      await createContent(createContentRequest);
      setShowSuccessBanner(true);
      setTimeout(() => {
        setShowSuccessBanner(false);
        navigate(`/campaign/${campaignId}`);
      }, 3000);
    } catch (error) {
      console.error('Failed to create content:', error);
    }
  };

  const handleCancel = () => {
    navigate(`/campaign/${campaignId}`);
  };

  return (
    <>
      {showSuccessBanner && (
        <Notification
          icon={<IconCheck size="1.1rem" />}
          color="teal"
          title="Success"
          onClose={() => setShowSuccessBanner(false)}
        >
          Content created successfully!
        </Notification>
      )}
      <ContentEditor onSave={handleSave} onCancel={handleCancel} isUpdating={isCreating} />
    </>
  );
}
