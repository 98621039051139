import React, { useState } from 'react';
import { Button, Group, Kbd, Text, Tooltip, Transition } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { useOs } from '@mantine/hooks';

export const AnimatedActionButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const os = useOs();
  const shortcutKey = os === 'macos' ? '⌘' : 'Ctrl';
  return (
    <Tooltip
      label={
        <>
          <Kbd>{shortcutKey}</Kbd> + <Kbd>K</Kbd>
        </>
      }
      openDelay={500}
    >
      <Button
        w={isHovered ? 220 : 50}
        h={50}
        p={0}
        style={{
          borderRadius: 25,
          transition: 'all 0.3s ease',
          overflow: 'hidden',
          position: 'relative',
        }}
        variant="gradient"
        gradient={{
          from: '#30B2AC',
          to: '#EBA487',
          deg: 90,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onClick}
      >
        <Transition mounted={!isHovered} transition="fade" duration={300}>
          {(styles) => (
            <IconSearch
              size={24}
              style={{
                ...styles,
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}
        </Transition>
        <Transition mounted={isHovered} transition="fade" duration={300}>
          {(styles) => (
            <Group
              style={{
                ...styles,
                position: 'absolute',
                left: 15,
                top: '50%',
                transform: 'translateY(-50%)',
                width: 'calc(100% - 30px)',
              }}
              wrap="nowrap"
            >
              <IconSearch size={24} style={{ flexShrink: 0 }} />
              <Text
                size="sm"
                fw={500}
                style={{
                  flexGrow: 1,
                  textAlign: 'center',
                }}
              >
                Start Your Next Quest!
              </Text>
            </Group>
          )}
        </Transition>
      </Button>
    </Tooltip>
  );
};
