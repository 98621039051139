import React, { useEffect } from 'react';
import { Avatar, Button, Card, Container, Group, Loader, Stack, Text, Title } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import { ProfileDTOTierEnum } from 'campaigner-client';
import { useAuth } from '@/hooks/useAuth';
import { useCampaigns } from '@/queries/campaignQueries';
import { getGravatarUrl } from '@/utils/gravatar';
import { CampaignsTable } from '@/components/campaign/CampaignsTable';

export function ProfilePage() {
  const { profile, isAuthenticated, loading } = useAuth();
  const { data: campaigns, isLoading: campaignsLoading } = useCampaigns();
  profile?.tier;
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate('/');
    }
  }, [loading, isAuthenticated, navigate]);

  if (loading || campaignsLoading) {
    return (
      <Container size="lg" py="xl">
        <Loader size="xl" />
      </Container>
    );
  }

  const avatarUrl = profile?.email
    ? getGravatarUrl(profile.email, 200)
    : 'https://example.com/placeholder-avatar.jpg';

  return (
    <Container size="lg" py="xl">
      <Title order={1} mb="xl">
        Your Adventurer&apos;s Profile
      </Title>
      <Stack gap="xl">
        <Card shadow="sm" p="lg" radius="md" withBorder>
          <Title order={2} mb="md">
            Personal Quest Log
          </Title>
          <Group align="flex-start">
            <Avatar size="xl" src={avatarUrl} alt="Profile Picture" />
            <Stack style={{ flex: 1 }}>
              <Text>
                <strong>Username:</strong> {profile?.username}
              </Text>
              <Text>
                <strong>Email:</strong> {profile?.email}
              </Text>
              <Text>
                <strong>True Name:</strong> {profile?.name}
              </Text>
            </Stack>
          </Group>
        </Card>

        <Card shadow="sm" p="lg" radius="md" withBorder>
          <Title order={2} mb="md">
            Campaigns
          </Title>
          <Stack>
            {campaigns && campaigns.length > 0 ? (
              <CampaignsTable campaigns={campaigns} />
            ) : (
              <Text>No campaigns joined yet. Time to start a new adventure!</Text>
            )}
          </Stack>
        </Card>

        <Card shadow="sm" p="lg" radius="md" withBorder>
          <Title order={2} mb="md">
            Legendary Status (Subscription)
          </Title>

          {(() => {
            switch (profile?.tier) {
              case ProfileDTOTierEnum.Free:
                return (
                  <>
                    <Text mb="md">Current Rank: Free Adventurer</Text>
                    <Button color="brand-teal">Ascend to Heroic Pro Status</Button>
                  </>
                );
              case ProfileDTOTierEnum.IndividualPremium:
                return (
                  <>
                    <Text mb="md">Current Rank: Individual Premium Hero</Text>
                    <Text color="brand-teal">
                      Thank you for your support! Enjoy your premium features.
                    </Text>
                  </>
                );
              case ProfileDTOTierEnum.PremiumSharing:
                return (
                  <>
                    <Text mb="md">Current Rank: Premium Sharing Champion</Text>
                    <Text color="brand-teal">
                      Thank you for your incredible support! Enjoy sharing your campaigns with
                      fellow adventurers.
                    </Text>
                  </>
                );
              case ProfileDTOTierEnum.YagTier:
                return (
                  <>
                    <Text mb="md">Current Rank: Legendary YAG Patron</Text>
                    <Text color="brand-teal">
                      We are immensely grateful for your extraordinary support! Your legendary
                      status fuels our quest to create the ultimate campaign experience.
                    </Text>
                  </>
                );
              default:
                return (
                  <>
                    <Text mb="md">Current Rank: Unknown</Text>
                    <Button color="brand-teal">Explore Subscription Options</Button>
                  </>
                );
            }
          })()}
        </Card>

        <Card shadow="sm" p="lg" radius="md" withBorder>
          <Title order={2} mb="md">
            Summon Aid (Support)
          </Title>
          <Text mb="md">
            Trapped in a puzzle? Our council of wise wizards stands ready to assist!
          </Text>
          <Button
            color="brand-orange"
            component={Link}
            to="https://campaignerapp.youtrack.cloud/form/f0c1cd03-7fc6-4d35-b57c-8f9495194c24"
            target="_blank"
          >
            Send a Magical Missive (Contact Support)
          </Button>
        </Card>
      </Stack>
    </Container>
  );
}
