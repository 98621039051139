import React from 'react';
import { Button, Modal, Stack, TextInput, Textarea, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { QuestDTO } from 'campaigner-client';

interface NewQuestModalProps {
  opened: boolean;
  onClose: () => void;
  onAddQuest: (newQuest: QuestDTO) => void;
}

export function NewQuestModal({ opened, onClose, onAddQuest }: NewQuestModalProps) {
  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      status: 'ACTIVE',
    },
    validate: {
      name: (value) => (value.trim().length > 0 ? null : 'Name is required'),
      status: (value) => (value ? null : 'Status is required'),
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    onAddQuest({
      id: `quest-${Date.now()}`,
      name: values.name,
      description: values.description,
      status: values.status as QuestDTO['status'],
      objectives: [],
    });
    form.reset();
    onClose();
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Add New Quest">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <TextInput label="Name" required {...form.getInputProps('name')} />
          <Textarea label="Description" {...form.getInputProps('description')} />
          <Select
            label="Status"
            required
            data={[
              {
                value: 'ACTIVE',
                label: 'Active',
              },
              {
                value: 'COMPLETED',
                label: 'Completed',
              },
              {
                value: 'FAILED',
                label: 'Failed',
              },
            ]}
            {...form.getInputProps('status')}
          />
          <Button type="submit">Add Quest</Button>
        </Stack>
      </form>
    </Modal>
  );
}
