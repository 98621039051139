import { useInfiniteQuery, useQuery, UseQueryResult } from '@tanstack/react-query';
import { ContentDTO, PaginatedResultContentPreviewDTO } from 'campaigner-client';
import { contentApi } from '@/api/apiClient';
import { handleApiResponse } from '@/utils/api.utils';

const PAGE_SIZE = 20;

export const useContents = (campaignId: string) =>
  useInfiniteQuery<PaginatedResultContentPreviewDTO, Error>({
    initialPageParam: null,
    queryKey: ['campaigns', campaignId, 'content'],
    queryFn: async ({ pageParam = null }) =>
      contentApi
        .listContents(campaignId, (pageParam as string) || undefined, PAGE_SIZE)
        .then((response) => handleApiResponse<PaginatedResultContentPreviewDTO>(response)),
    getNextPageParam: (lastPage) => lastPage.lastEvaluatedKey || undefined,
    select: (data) => ({
      ...data,
      pages: data.pages.map((page) => ({
        ...page,
        items: page.items.filter((item) => item.contentCategory !== 'QUEST'),
      })),
    }),
  });

export const useQuestLogContent = (campaignId: string): UseQueryResult<ContentDTO | null, Error> =>
  useQuery({
    queryKey: ['campaigns', campaignId, 'questLog'],
    queryFn: async () =>
      contentApi
        .listContents(campaignId, undefined, 1000)
        .then((res) => handleApiResponse<PaginatedResultContentPreviewDTO>(res))
        .then(({ items }) => items.filter((item) => item.contentCategory === 'QUEST'))
        .then((questLogs) => questLogs.pop())
        .then((quest) => contentApi.getContent(campaignId, quest?.id || ''))
        .then((res) => handleApiResponse<ContentDTO>(res)),
  });

export const useContent = (
  campaignId: string,
  contentId: string
): UseQueryResult<ContentDTO, Error> =>
  useQuery({
    queryKey: ['campaigns', campaignId, 'content', contentId],
    queryFn: async () =>
      contentApi
        .getContent(campaignId, contentId)
        .then((response) => handleApiResponse<ContentDTO>(response)),
  });
