import {
  Box,
  Burger,
  Button,
  Divider,
  Drawer,
  Group,
  Image,
  Menu,
  rem,
  ScrollArea,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';
import { IconChevronDown, IconLogout } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import classes from './TopNavigationBar.module.css';
import { useAuth } from '@/hooks/useAuth';
import { useAuthInitialization } from '@/queries/authQueries';

export function TopNavigationBar() {
  const navigate = useNavigate();
  const { isLoading } = useAuthInitialization();
  const { profile, logout, isAuthenticated } = useAuth();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const [, setUserMenuOpened] = React.useState(false);

  const handleLogout = async () => {
    logout();
    navigate('/');
    navigate(0);
  };

  function handleMyCampaigns() {
    navigate('/campaigns');
  }

  return (
    <Box pb={12}>
      <header className={classes.header}>
        <Group h="100%" grow justify="space-between">
          <Group grow justify="space-between" align="center">
            {/* Logo and App Name */}
            <Group gap="xs" align="center">
              <Image src="/logo.png" w={80} h={80} alt="The Campaigner" />
              <Text component={Link} to="/" size="md" style={{ userSelect: 'none' }}>
                The Campaigner
              </Text>
            </Group>
          </Group>
          <Group justify="flex-end" visibleFrom="sm">
            <Button component={Link} to="/" variant="subtle" c="white">
              Home
            </Button>

            {!isLoading && !isAuthenticated && (
              <Button component={Link} to="/login">
                Log In
              </Button>
            )}
            {(isAuthenticated || isLoading) && (
              <Menu
                width={260}
                position="bottom-end"
                transitionProps={{ transition: 'pop-top-right' }}
                onClose={() => setUserMenuOpened(false)}
                onOpen={() => setUserMenuOpened(true)}
                withinPortal
              >
                <Menu.Target>
                  <Button loading={isLoading} color="brand-dark-teal" radius="xs">
                    <Group gap={7}>
                      <Text fw={500} size="sm" lh={1} mr={3}>
                        {profile ? profile.username : 'Loading...'}
                      </Text>
                      <IconChevronDown
                        style={{
                          width: rem(12),
                          height: rem(12),
                        }}
                        stroke={1.5}
                      />
                    </Group>
                  </Button>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item onClick={() => handleMyCampaigns()}>My Campaigns</Menu.Item>
                  <Menu.Divider />
                  <Menu.Label>Settings</Menu.Label>
                  <Menu.Item
                    onClick={() => handleLogout()}
                    leftSection={
                      <IconLogout
                        style={{
                          width: rem(16),
                          height: rem(16),
                        }}
                        stroke={1.5}
                      />
                    }
                  >
                    Logout
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
          </Group>

          <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" />
        </Group>
      </header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        hiddenFrom="sm"
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
          <Divider my="sm" />

          <Link to="/" className={classes.link} onClick={closeDrawer}>
            Home
          </Link>
          {/*          <Link to="/pricing" className={classes.link} onClick={closeDrawer}>
            Pricing
          </Link>*/}
          <Link to="/about" className={classes.link} onClick={closeDrawer}>
            About Us
          </Link>
          <Link to="/contact" className={classes.link} onClick={closeDrawer}>
            Contact
          </Link>
          {isAuthenticated && (
            <Link to="/campaigns" className={classes.link} onClick={closeDrawer}>
              My Campaigns
            </Link>
          )}

          <Divider my="sm" />

          <Group justify="center" grow pb="xl" px="md">
            {!isAuthenticated ? (
              <Button component={Link} to="/login" onClick={closeDrawer}>
                Log in
              </Button>
            ) : (
              <Button onClick={handleLogout}>Log out</Button>
            )}
          </Group>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}
