import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  Center,
  Container,
  Loader,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { IconCheck, IconMap, IconSword, IconX } from '@tabler/icons-react';
import { supabase } from '@/api/supabaseClient';

export function ConfirmForm() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    const confirmSignUp = async () => {
      const token_hash = searchParams.get('token_hash');
      const type = searchParams.get('type');
      if (token_hash && type) {
        try {
          const { error } = await supabase.auth.verifyOtp({
            token_hash,
            type: 'signup',
          });

          if (error) throw error;

          setIsConfirmed(true);
        } catch (err) {
          setError(err instanceof Error ? err.message : 'An error occurred during confirmation');
        } finally {
          setIsLoading(false);
        }
      } else {
        setError('Invalid confirmation link');
        setIsLoading(false);
      }
    };

    confirmSignUp();
  }, [searchParams]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <Stack align="center" gap="xl">
          <ThemeIcon size={80} radius="xl" variant="light" color="blue">
            <IconMap size={50} />
          </ThemeIcon>
          <Title order={2}>Deciphering the Ancient Runes...</Title>
          <Loader size="xl" variant="bars" />
          <Text size="lg" ta="center">
            Hold tight, brave adventurer! We&apos;re confirming your legendary status.
          </Text>
        </Stack>
      );
    }

    if (error) {
      return (
        <Stack align="center" gap="xl">
          <ThemeIcon size={80} radius="xl" variant="light" color="red">
            <IconX size={50} />
          </ThemeIcon>
          <Title order={2}>A Wild Error Appears!</Title>
          <Text color="red" size="lg" ta="center">
            {error}
          </Text>
          <Text size="md" ta="center">
            Fear not! Even the mightiest heroes face setbacks. Shall we try again?
          </Text>
          <Button size="lg" onClick={() => navigate('/login')}>
            Return to the Guild Hall (Login)
          </Button>
        </Stack>
      );
    }

    if (isConfirmed) {
      return (
        <Stack align="center" gap="xl">
          <ThemeIcon size={80} radius="xl" variant="light" color="green">
            <IconCheck size={50} />
          </ThemeIcon>
          <Title order={2}>Victory! Your Quest Begins!</Title>
          <Text size="lg" ta="center">
            Huzzah! Your email has been confirmed. You&apos;re now ready to embark on epic campaign
            management adventures!
          </Text>
          <Button
            size="lg"
            leftSection={<IconSword size={20} />}
            onClick={() => navigate('/login')}
          >
            Enter the Realm (Login)
          </Button>
        </Stack>
      );
    }

    return null;
  };

  return (
    <Center
      style={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <Container size="sm">
        <Paper radius="md" p="xl" withBorder>
          {renderContent()}
        </Paper>
      </Container>
    </Center>
  );
}
