import React from 'react';
import { Image } from '@mantine/core';

export interface ImageViewerProps {
  url?: string;
}

export default function ImageViewer({ url }: ImageViewerProps) {
  return <Image src={url} maw="80vh" />;
}
