import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Container, Text, Title } from '@mantine/core';
import { ContentPreviewDTO } from 'campaigner-client';
import { useContents } from '@/queries/contentQueries';

export function CampaignMapsPage() {
  const { campaignId } = useParams<{ campaignId: string }>();
  const { data: contentData, isLoading, error } = useContents(campaignId || '');
  const [maps, setMaps] = useState<ContentPreviewDTO[]>([]);
  if (isLoading) return <Text>Loading maps...</Text>;
  if (error) return <Text>Error loading maps: {error.message}</Text>;

  useEffect(() => {
    if (contentData?.pages) {
      const mapPreviews = contentData.pages.flatMap((page) => {
        if (page.items) {
          return page.items.filter((item) => item.contentCategory === 'MAP');
        }
        return [];
      });
      setMaps(mapPreviews);
    }
  }, [contentData]);

  return (
    <Container>
      <Title order={1} mb="xl">
        Campaign Maps
      </Title>
      {maps.length === 0 ? (
        <Text>No maps available for this campaign.</Text>
      ) : (
        <>
          {maps.map((map) => (
            <Button
              variant="subtle"
              color="blue"
              radius="xs"
              component={Link}
              key={map.id}
              to={`/campaign/${campaignId}/content/${map.id}`}
            >
              {map.title}
            </Button>
          ))}
        </>
      )}
    </Container>
  );
}
