import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CampaignDTORoleEnum, CampaignMembers } from 'campaigner-client';
import { campaignApi } from '@/api/apiClient';
import { handleApiResponse } from '@/utils/api.utils';

export const useAddMember = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      campaignId,
      profileId,
      role,
    }: {
      campaignId: string;
      profileId: string;
      role: CampaignDTORoleEnum;
    }) =>
      campaignApi
        .addCampaignMembers(campaignId, {
          profileId,
          role,
        })
        .then((res) => handleApiResponse<CampaignMembers>(res)),
    onSuccess: (members) => {
      queryClient.setQueryData(['campaigns', members.campaignId, 'members'], members);
    },
  });
};

export const useRemoveMember = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ campaignId, profileId }: { campaignId: string; profileId: string }) =>
      campaignApi.removeCampaignMember(campaignId, profileId).then(() => campaignId),
    onSuccess: (campaignId) => {
      queryClient.invalidateQueries({
        queryKey: ['campaigns', campaignId, 'members'],
        refetchType: 'all',
      });
    },
  });
};
