import React, { useState } from 'react';
import { Button, Stack } from '@mantine/core';
import { ContentDTOContentCategoryEnum } from 'campaigner-client';

import { stripExtension, toTitleCase } from '@/utils/string.utils';
import { ContentFormat } from '@/types/alias.types';
import { detectContentType, encodeImageToBase64, readFileAsText } from '@/utils/file.utils';
import { ContentCreationForm } from '@/components/campaign/content-creation-form/ContentCreationForm';
import { FileUploader } from '@/components/general/file-uploader/FileUploader';
import { useContentCreation } from '@/hooks/useContentCreation';

interface ContentUploaderProps {
  campaignId: string;
}

export function ContentUploader({ campaignId }: ContentUploaderProps) {
  const [file, setFile] = useState<File | null>(null);
  const { title, setTitle, contentType, setContentType, isLoading, handleCreateContent } =
    useContentCreation(campaignId);

  const handleFileDrop = async (droppedFile: File) => {
    setFile(droppedFile);
    setTitle(toTitleCase(stripExtension(droppedFile.name)));
    const detectedType = await detectContentType(droppedFile);
    setContentType(detectedType);
  };

  const getAvailableContentCategories = (): ContentFormat[] => {
    if (contentType === 'PICTURE' || contentType === 'MAP') {
      return ['PICTURE', 'MAP'];
    }
    return (Object.values(ContentDTOContentCategoryEnum) as ContentFormat[]).filter(
      (category) => category !== 'PICTURE' && category !== 'MAP' && category !== 'QUEST'
    );
  };

  const handleSubmit = async () => {
    if (!file || !contentType) return;
    let contentString: string;
    if (contentType === 'PICTURE' || contentType === 'MAP') {
      contentString = await encodeImageToBase64(file);
    } else {
      contentString = await readFileAsText(file);
    }
    await handleCreateContent(contentString);
    setFile(null);
  };

  return (
    <Stack>
      {!file ? (
        <FileUploader onFileDrop={handleFileDrop} isLoading={isLoading} />
      ) : (
        <>
          <ContentCreationForm
            title={title}
            setTitle={setTitle}
            contentType={contentType}
            setContentType={setContentType}
            availableCategories={getAvailableContentCategories()}
          />
          <Button onClick={handleSubmit} loading={isLoading}>
            Submit
          </Button>
        </>
      )}
    </Stack>
  );
}
