import React from 'react';
import { Select, Stack, TextInput } from '@mantine/core';
import { toTitleCase } from '@/utils/string.utils';
import { ContentFormat } from '@/types/alias.types';

interface ContentCreationFormProps {
  title: string;
  setTitle: (title: string) => void;
  contentType: ContentFormat | undefined;
  setContentType: (contentType: ContentFormat | undefined) => void;
  availableCategories: ContentFormat[];
}

export function ContentCreationForm({
  title,
  setTitle,
  contentType,
  setContentType,
  availableCategories,
}: ContentCreationFormProps) {
  return (
    <Stack>
      <TextInput
        label="Title"
        value={title}
        onChange={(event) => setTitle(event.currentTarget.value)}
        placeholder="Enter content title"
      />
      <Select
        label="Category"
        placeholder="Select a category"
        data={availableCategories.map((category) => ({
          value: category,
          label: toTitleCase(category.replace('_', ' ')),
        }))}
        value={contentType}
        onChange={(value) => setContentType(value as ContentFormat)}
      />
    </Stack>
  );
}
