import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { CampaignDTO, LimitedCampaignDTO } from 'campaigner-client';
import { campaignApi } from '@/api/apiClient';
import { handleApiResponse } from '@/utils/api.utils';

export const useCampaigns = (): UseQueryResult<CampaignDTO[], Error> =>
  useQuery({
    queryKey: ['campaigns'],
    queryFn: async () =>
      campaignApi.listCampaigns().then((res) => handleApiResponse<CampaignDTO[]>(res)),
  });

export const useCampaign = (campaignId: string): UseQueryResult<CampaignDTO, Error> =>
  useQuery({
    queryKey: ['campaigns', campaignId],
    queryFn: async () =>
      campaignApi.getCampaign(campaignId).then((res) => handleApiResponse<CampaignDTO>(res)),
  });

export const useLimitedCampaign = (
  campaignId: string,
  inviteLink: string
): UseQueryResult<LimitedCampaignDTO, Error> =>
  useQuery({
    queryKey: ['campaigns', campaignId, 'limited'],
    queryFn: async () =>
      campaignApi
        .getLimitedCampaignInfo(campaignId, inviteLink)
        .then((res) => handleApiResponse<LimitedCampaignDTO[]>(res)),
  });
