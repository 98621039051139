import React, { Dispatch, SetStateAction } from 'react';
import { Burger, Group, Image, Title } from '@mantine/core';
import { UserMenu } from '@/components/campaign/app-shell/UserMenu';

interface AppHeaderProps {
  isMobile: boolean;
  mobileOpened: boolean;
  setMobileOpened: Dispatch<SetStateAction<boolean>>;
  setDesktopExpanded: Dispatch<SetStateAction<boolean>>;
  campaignName: string;
  userName: string;
  onLogout: () => void;
}

export function AppHeader({
  isMobile,
  mobileOpened,
  setMobileOpened,
  setDesktopExpanded,
  campaignName,
  userName,
  onLogout,
}: AppHeaderProps) {
  return (
    <Group h="100%" px="md" justify="space-between">
      {isMobile && (
        <Burger
          opened={mobileOpened}
          onClick={() => (isMobile ? setMobileOpened((o) => !o) : setDesktopExpanded((e) => !e))}
          size="sm"
        />
      )}
      {!isMobile && (
        <Group>
          <Image src="/logo.png" w={60} h={60} />
          <Title size="h3">{campaignName}</Title>
        </Group>
      )}
      <UserMenu userName={userName} onLogout={onLogout} />
    </Group>
  );
}
