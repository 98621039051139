import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { CreateProfileRequest, ProfileDTO } from 'campaigner-client';
import { profileApi } from '@/api/apiClient';
import { handleApiResponse } from '@/utils/api.utils';

export const useProfile = (): UseQueryResult<ProfileDTO, Error> =>
  useQuery({
    queryKey: ['profile'],
    queryFn: async () =>
      profileApi.getProfile().then((response) => handleApiResponse<ProfileDTO>(response)),
  });

export const useCreateProfile = () =>
  useMutation({
    mutationFn: async (request: CreateProfileRequest) =>
      profileApi.createProfile(request).then((res) => handleApiResponse<ProfileDTO>(res)),
  });
