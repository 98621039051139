import { SpotlightActionData } from '@mantine/spotlight';

export class SpotlightManager {
  private baseActions: SpotlightActionData[] = [];
  private authActions: SpotlightActionData[] = [];
  private campaignActions: SpotlightActionData[] = [];
  private contextActions: SpotlightActionData[] = [];

  constructor(private setStoreActions: (actions: SpotlightActionData[]) => void) {}

  private updateStore() {
    const allActions = [
      ...this.baseActions,
      ...this.authActions,
      ...this.campaignActions,
      ...this.contextActions,
    ];
    this.setStoreActions(allActions);
  }

  addBaseActions(actions: SpotlightActionData[]) {
    this.baseActions = actions;
    this.updateStore();
  }

  addAuthActions(actions: SpotlightActionData[], isAuthenticated: boolean) {
    this.authActions = isAuthenticated ? actions : [];
    this.updateStore();
  }

  addCampaignActions(actions: SpotlightActionData[]) {
    this.campaignActions = actions;
    this.updateStore();
  }

  addContextActions(actions: SpotlightActionData[]) {
    this.contextActions = [...this.contextActions, ...actions];
    this.updateStore();
  }

  removeContextActions(ids: string[]) {
    this.contextActions = this.contextActions.filter((action) => !ids.includes(action.id));
    this.updateStore();
  }
}
