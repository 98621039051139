import React, { useEffect, useMemo } from 'react';
import { Button, Container, Select, Stack, TextInput } from '@mantine/core';
import { useEditor } from '@tiptap/react';
import { RichTextEditor } from '@mantine/tiptap';
import { Markdown } from 'tiptap-markdown';
import Link from '@tiptap/extension-link';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import { ContentDTO, ContentDTOContentCategoryEnum } from 'campaigner-client';
import { toTitleCase } from '@/utils/string.utils';
import { ContentFormat } from '@/types/alias.types';

interface ContentEditorProps {
  initialContent?: Partial<ContentDTO>;
  onSave: (content: Partial<ContentDTO>) => Promise<void>;
  onCancel: () => void;
  isUpdating: boolean;
}

export function ContentEditor({
  initialContent,
  onSave,
  onCancel,
  isUpdating,
}: ContentEditorProps) {
  const [title, setTitle] = React.useState(initialContent?.title);
  const [category, setCategory] = React.useState<ContentFormat>(
    initialContent?.contentCategory || 'LORE'
  );

  const editor = useEditor({
    extensions: [StarterKit, Link, Highlight, Underline, Markdown],
    content: initialContent?.content || '',
  });

  useEffect(() => {
    if (editor && !editor.isDestroyed && initialContent?.content) {
      editor.commands.setContent(initialContent.content);
    }
  }, [initialContent?.content, editor]);

  const categoryOptions = useMemo(
    () =>
      Object.entries(ContentDTOContentCategoryEnum)
        .filter(([_, value]) => value !== 'PICTURE' && value !== 'MAP' && value !== 'QUEST')
        .map(([key, value]) => ({
          value,
          label: toTitleCase(value.replace('_', ' ')),
        })),
    []
  );

  const handleSave = async () => {
    if (editor) {
      const markdown = editor.storage.markdown.getMarkdown();
      const newContent: Partial<ContentDTO> = {
        ...initialContent,
        title: title || '',
        content: markdown,
        contentCategory: category,
      };
      try {
        await onSave(newContent);
      } catch (error) {
        console.error('Failed to save content:', error);
      }
    }
  };

  return (
    <Container size="lg">
      <Stack gap="md">
        <TextInput
          label="Title"
          value={title}
          onChange={(event) => setTitle(event.currentTarget.value)}
          placeholder="Enter content title"
        />
        <Select
          label="Category"
          value={category}
          onChange={(value) => setCategory(value as ContentFormat)}
          data={categoryOptions}
        />
        <RichTextEditor editor={editor}>
          <RichTextEditor.Toolbar sticky stickyOffset={0}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Strikethrough />
              <RichTextEditor.ClearFormatting />
              <RichTextEditor.Highlight />
              <RichTextEditor.Code />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H1 />
              <RichTextEditor.H2 />
              <RichTextEditor.H3 />
              <RichTextEditor.H4 />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Blockquote />
              <RichTextEditor.Hr />
              <RichTextEditor.BulletList />
              <RichTextEditor.OrderedList />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Link />
              <RichTextEditor.Unlink />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Undo />
              <RichTextEditor.Redo />
            </RichTextEditor.ControlsGroup>
          </RichTextEditor.Toolbar>

          <RichTextEditor.Content />
        </RichTextEditor>
        <Button onClick={handleSave} loading={isUpdating} disabled={isUpdating}>
          {isUpdating ? 'Saving...' : 'Save'}
        </Button>
        <Button onClick={onCancel} variant="outline" disabled={isUpdating}>
          Cancel
        </Button>
      </Stack>
    </Container>
  );
}
