import { useQuery } from '@tanstack/react-query';
import { ProfileDTO } from 'campaigner-client';
import { profileApi } from '@/api/apiClient';
import { handleApiResponse } from '@/utils/api.utils';

export const useAuthInitialization = () =>
  useQuery({
    queryKey: ['authInitialization'],
    queryFn: async () => {
      try {
        const response = await profileApi.getProfile();
        return handleApiResponse<ProfileDTO>(response);
      } catch (error) {
        return undefined;
      }
    },
    retry: 2,
    staleTime: Infinity,
  });
