import React from 'react';
import { Badge, Card, Group, MantineColor, Text, Tooltip } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { ContentPreviewDTO } from 'campaigner-client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DefaultMantineColor } from '@mantine/core/lib/core/MantineProvider/theme.types';

dayjs.extend(relativeTime);

interface ContentCardProps {
  content: ContentPreviewDTO;
  campaignId: string;
}

export function ContentCard({ content, campaignId }: ContentCardProps) {
  const navigate = useNavigate();
  const updatedAt = dayjs(content.updatedAt);
  const humanTime = updatedAt.fromNow();
  const fullDate = updatedAt.format('MMMM D, YYYY h:mm A');
  const getCategoryColor = (category: string): MantineColor => {
    const colorMap: { [key: string]: DefaultMantineColor } = {
      LORE: 'blue',
      QUEST: 'green',
      CHARACTER: 'yellow',
      ITEM: 'orange',
      TIMELINE: 'grape',
      SESSION_NOTES: 'cyan',
      PICTURE: 'pink',
      MAP: 'red',
    };
    return (colorMap[category] || ('gray' as DefaultMantineColor)) as MantineColor;
  };
  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      onClick={() => navigate(`/campaign/${campaignId}/content/${content.id}`)}
    >
      <Group mb="xs">
        <Text>{content.title}</Text>
        <Badge color={getCategoryColor(content.contentCategory)} variant="light">
          {content.contentCategory}
        </Badge>
      </Group>

      <Text size="sm" color="dimmed" lineClamp={2}>
        {content.metadata?.description || 'No description available'}
      </Text>

      <Tooltip label={fullDate}>
        <Text size="xs" color="dimmed" mt="md">
          Last modified: {humanTime}
        </Text>
      </Tooltip>
    </Card>
  );
}
