import React, { useState, useEffect } from 'react';
import { Box } from '@mantine/core';
import classes from './BackgroundSlider.module.css';

interface BackgroundSliderProps {
  images: string[];
  interval?: number;
}

export function BackgroundSlider({ images, interval = 10000 }: BackgroundSliderProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(timer);
  }, [images, interval]);

  return (
    <Box
      className={classes.backgroundSlider}
      style={{
        backgroundImage: `url(${images[currentImageIndex]})`,
      }}
    />
  );
}
