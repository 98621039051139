import React, { useEffect, useState } from 'react';
import { upperFirst } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import {
  Anchor,
  Button,
  Center,
  Container,
  Divider,
  Group,
  Paper,
  PaperProps,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/hooks/useAuth';
import { DiscordButton, GoogleButton } from '@/components/auth/social-buttons/SocialButtons';

export function AuthenticationForm(props: PaperProps) {
  const navigate = useNavigate();
  const { login, signup, signInWithDiscord, signInWithGoogle, isAuthenticated } = useAuth();
  const [type, setType] = useState<'login' | 'register'>('login');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<'discord' | 'google' | 'user-pass' | undefined>();

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
      password: (val) => (val.length < 6 ? 'Password should include at least 6 characters' : null),
    },
  });

  const handleSubmit = async (values: typeof form.values) => {
    setError(null);
    setLoading('user-pass');
    if (type === 'login') {
      await login(values.email, values.password)
        .then(() => {
          navigate('/campaigns');
        })
        .catch((err) => {
          setError('An error occurred while logging in. Please try again..');
          console.error('Error', err);
        });
    } else {
      await signup(values.email, values.password)
        .then(() => {
          navigate('/complete-profile');
        })
        .catch((err) => {
          setError('An error occurred while logging in. Please try again..');
          console.error('Error', err);
        });
    }
  };

  const handleSocialLogin = async (provider: 'discord' | 'google') => {
    setError(null);
    if (provider === 'discord') {
      setLoading('discord');
      await signInWithDiscord()
        .then(() => {
          navigate('/campaigns');
        })
        .catch((err) => {
          setError('An error occurred while logging in. Please try again..');
          console.error('Error', err);
        });
    } else {
      setLoading('google');
      await signInWithGoogle()
        .then(() => {
          navigate('/campaigns');
        })
        .catch((err) => {
          setError('An error occurred while logging in. Please try again..');
          console.error('Error', err);
        });
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/campaigns');
    }
  }, [isAuthenticated]);
  return (
    <Center
      style={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <Container size={420} my={40}>
        <Paper radius="md" p="xl" withBorder {...props}>
          <Text size="lg" fw={500} ta="center" mb="md">
            Welcome to Campaigner, {type} with
          </Text>

          <Group grow mb="md" mt="md">
            <GoogleButton loading={!!loading} onClick={() => handleSocialLogin('google')}>
              Google
            </GoogleButton>
            <DiscordButton loading={!!loading} onClick={() => handleSocialLogin('discord')}>
              Discord
            </DiscordButton>
          </Group>

          <Divider label="Or continue with email" labelPosition="center" my="lg" />

          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack>
              <TextInput
                required
                label="Email"
                placeholder="hello@example.com"
                value={form.values.email}
                onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                error={form.errors.email && 'Invalid email'}
                radius="md"
              />

              <PasswordInput
                required
                label="Password"
                placeholder="Your password"
                value={form.values.password}
                onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                error={form.errors.password && 'Password should include at least 6 characters'}
                radius="md"
              />
            </Stack>

            {error && (
              <Text color="red" size="sm" mt="sm">
                {error}
              </Text>
            )}

            <Group justify="space-between" mt="xl">
              <Anchor
                component="button"
                type="button"
                c="dimmed"
                onClick={() => setType(type === 'register' ? 'login' : 'register')}
                size="xs"
              >
                {type === 'register'
                  ? 'Already have an account? Login'
                  : "Don't have an account? Register"}
              </Anchor>
              <Button type="submit" radius="xl" loading={!!loading}>
                {upperFirst(type)}
              </Button>
            </Group>
          </form>
        </Paper>
      </Container>
    </Center>
  );
}
