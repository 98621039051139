import React from 'react';
import { Box, Container, Group, Text } from '@mantine/core';

export function Footer() {
  return (
    <Box component="footer" py="md" mt="auto">
      <Container>
        <Group justify="space-between">
          <Text size="sm">© 2024 Binary Hive Technologies Inc. All rights reserved.</Text>
          <Group gap="xs" justify="flex-end" wrap="nowrap">
            <Text size="sm" component="a" href="/about" c="dimmed">
              About
            </Text>
            <Text size="sm" component="a" href="/contact" c="dimmed">
              Contact
            </Text>
            <Text size="sm" component="a" href="/privacy.html" c="dimmed">
              Privacy Policy
            </Text>
            <Text size="sm" component="a" href="/tos.html" c="dimmed">
              Terms and Conditions
            </Text>
          </Group>
        </Group>
      </Container>
    </Box>
  );
}
