import React from 'react';
import {
  Button,
  Card,
  Container,
  Group,
  Stack,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { IconBrandDiscord, IconBrandTwitter, IconMail } from '@tabler/icons-react';

export function ContactUsPage() {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted');
  };

  return (
    <Container size="md">
      <Stack gap="xl" my={50}>
        <Title order={1} ta="center" size="h1">
          Contact Us
        </Title>

        <Text size="xl" ta="center">
          Got questions? Feedback? A cool idea for a feature? We&apos;re all ears (even the elf
          ones)!
        </Text>

        <Group justify="center" gap="xl">
          <Card shadow="sm" padding="lg" radius="md" withBorder>
            <Stack align="center" gap="md">
              <ThemeIcon size={50} radius="md" variant="light" color="blue">
                <IconMail size={30} />
              </ThemeIcon>
              <Text fw={700} size="lg">
                Email Us
              </Text>
              <Text ta="center">support@campaignerapp.com</Text>
              <Text size="sm" c="dimmed" ta="center">
                For when you need a detailed answer (or just want to say hi)
              </Text>
            </Stack>
          </Card>

          <Card shadow="sm" padding="lg" radius="md" withBorder>
            <Stack align="center" gap="md">
              <ThemeIcon size={50} radius="md" variant="light" color="cyan">
                <IconBrandTwitter size={30} />
              </ThemeIcon>
              <Text fw={700} size="lg">
                Tweet at Us
              </Text>
              <Text ta="center">@campaignerapp</Text>
              <Text size="sm" c="dimmed" ta="center">
                For quick questions, shoutouts, or to share your epic campaign moments
              </Text>
            </Stack>
          </Card>

          <Card shadow="sm" padding="lg" radius="md" withBorder>
            <Stack align="center" gap="md">
              <ThemeIcon size={50} radius="md" variant="light" color="violet">
                <IconBrandDiscord size={30} />
              </ThemeIcon>
              <Text fw={700} size="lg">
                Join Our Discord
              </Text>
              <Text ta="center">discord.gg/campaignerapp</Text>
              <Text size="sm" c="dimmed" ta="center">
                For community discussions, feature requests, and virtual high-fives
              </Text>
            </Stack>
          </Card>
        </Group>

        <Title order={2} ta="center" mt="xl">
          Or Send Us a Message
        </Title>
        <form onSubmit={handleSubmit}>
          <Stack gap="md">
            <TextInput required label="Name" placeholder="Gandalf the Grey" />
            <TextInput required label="Email" placeholder="wizard@middleearth.com" type="email" />
            <TextInput
              label="Subject"
              placeholder="You shall not pass... without answering my question!"
            />
            <Textarea
              required
              label="Message"
              placeholder="Tell us what's on your mind (magical or otherwise)"
              minRows={4}
            />
            <Button type="submit" size="lg">
              Send Message (No Owl Required)
            </Button>
          </Stack>
        </form>

        <Text ta="center" mt="xl" size="lg">
          We&apos;ll get back to you faster than a rogue can say &quot;I steal from the party&quot;!
        </Text>
      </Stack>
    </Container>
  );
}
