import React, { useRef } from 'react';
import { Dropzone, FileWithPath } from '@mantine/dropzone';
import { Button, Group, rem, Text } from '@mantine/core';
import { IconCloudUpload, IconDownload, IconX } from '@tabler/icons-react';
import classes from './FileUploader.module.css';

interface FileUploaderProps {
  onFileDrop: (file: File) => void;
  isLoading: boolean;
}

export function FileUploader({ onFileDrop, isLoading }: FileUploaderProps) {
  const openRef = useRef<() => void>(null);

  const handleDrop = (files: FileWithPath[]) => {
    if (files.length > 0) {
      onFileDrop(files[0]);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Dropzone
        loading={isLoading}
        openRef={openRef}
        className={classes.dropzone}
        radius="md"
        onDrop={handleDrop}
        multiple={false}
        maxSize={5 * 1024 ** 2}
      >
        <div style={{ pointerEvents: 'none' }}>
          <Group justify="center">
            <Dropzone.Accept>
              <IconDownload
                style={{
                  width: rem(50),
                  height: rem(50),
                }}
                color="green"
                stroke={1.5}
              />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX
                style={{
                  width: rem(50),
                  height: rem(50),
                }}
                color="red"
                stroke={1.5}
              />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconCloudUpload
                style={{
                  width: rem(50),
                  height: rem(50),
                }}
                stroke={1.5}
              />
            </Dropzone.Idle>
          </Group>
          <Text ta="center" fw={700} fz="lg" mt="xl">
            <Dropzone.Accept>Drop your content here</Dropzone.Accept>
            <Dropzone.Reject>Only markdown and pictures are currently supported</Dropzone.Reject>
            <Dropzone.Idle>Upload new content</Dropzone.Idle>
          </Text>
          <Text ta="center" fz="sm" mt="xs" c="dimmed">
            Drag&apos;n&apos;drop files here to upload.
          </Text>
        </div>
      </Dropzone>
      <Button className={classes.control} size="md" radius="xl" onClick={() => openRef.current?.()}>
        Select file
      </Button>
    </div>
  );
}
