import React, { useState } from 'react';
import { Accordion, Badge, Button, Group, Text, Timeline } from '@mantine/core';
import { ObjectiveDTO, QuestDTO } from 'campaigner-client';
import { IconPlus } from '@tabler/icons-react';
import { ObjectiveItem } from './ObjectiveItem';
import { NewObjectiveModal } from '@/components/campaign/quest-log/NewObjectiveModal';

interface QuestItemProps {
  quest: QuestDTO;
  editMode: boolean;
  onUpdateQuest: (updatedQuest: QuestDTO) => void;
}

export function QuestItem({ quest, editMode, onUpdateQuest }: QuestItemProps) {
  const [newObjectiveModal, setNewObjectiveModal] = useState(false);

  const handleAddObjective = (newObjective: ObjectiveDTO) => {
    const updatedQuest = {
      ...quest,
      objectives: [...quest.objectives, newObjective],
    };
    onUpdateQuest(updatedQuest);
  };

  return (
    <Accordion.Item key={quest.id} value={quest.id || ''}>
      <Accordion.Control>
        <Group>
          <Text>{quest.name}</Text>
          <Badge
            color={
              quest.status === 'ACTIVE' ? 'blue' : quest.status === 'COMPLETED' ? 'green' : 'red'
            }
          >
            {quest.status}
          </Badge>
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        <Text mb="sm">{quest.description}</Text>
        <Timeline
          active={quest.objectives.filter((obj) => obj.completed).length}
          bulletSize={24}
          lineWidth={2}
        >
          {quest.objectives.map((objective) => (
            <ObjectiveItem
              key={objective.id}
              objective={objective}
              editMode={editMode}
              onUpdateObjective={(updatedObjective) => {
                const updatedQuest = {
                  ...quest,
                  objectives: quest.objectives.map((obj) =>
                    obj.id === updatedObjective.id ? updatedObjective : obj
                  ),
                };
                onUpdateQuest(updatedQuest);
              }}
            />
          ))}
        </Timeline>
        {editMode && (
          <Button
            leftSection={<IconPlus size={14} />}
            variant="light"
            size="sm"
            mt="sm"
            onClick={() => setNewObjectiveModal(true)}
          >
            Add Objective
          </Button>
        )}
      </Accordion.Panel>
      <NewObjectiveModal
        opened={newObjectiveModal}
        onClose={() => setNewObjectiveModal(false)}
        onAddObjective={handleAddObjective}
      />
    </Accordion.Item>
  );
}
