import React, { useState } from 'react';
import { Collapse, NavLink, Tooltip } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import type { MantineStyleProp } from '@mantine/core/lib/core/Box/Box.types';

interface NavItemProps {
  icon: React.ReactNode;
  label: React.ReactNode;
  onClick: () => void;
  subItems?: Array<{ id: string; title: string; onClick: () => void }>;
  expanded: boolean;
  onMobileNavClose: () => void;
  style?: MantineStyleProp;
}

export function NavItem({
  icon,
  label,
  onClick,
  subItems,
  expanded,
  onMobileNavClose,
  style,
}: NavItemProps) {
  const [opened, setOpened] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (subItems && subItems.length > 0) {
      event.preventDefault();
      setOpened((o) => !o);
    } else {
      onClick();
      onMobileNavClose();
    }
  };

  const navLink = (
    <NavLink
      label={expanded ? label : undefined}
      leftSection={icon}
      rightSection={
        subItems && subItems.length > 0 && expanded ? (
          <IconChevronRight
            size="0.8rem"
            stroke={1.5}
            style={{ transform: opened ? 'rotate(90deg)' : 'none' }}
          />
        ) : null
      }
      onClick={handleClick}
      style={style}
    />
  );

  return (
    <>
      {expanded ? (
        navLink
      ) : (
        <Tooltip label={label} position="right">
          {navLink}
        </Tooltip>
      )}
      {subItems && subItems.length > 0 && (
        <Collapse in={opened && expanded}>
          {subItems.map((item) => (
            <NavLink
              key={item.id}
              label={item.title}
              pl="xl"
              onClick={(event) => {
                event.stopPropagation();
                item.onClick();
                onMobileNavClose();
              }}
            />
          ))}
        </Collapse>
      )}
    </>
  );
}
