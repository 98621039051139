import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Flex } from '@mantine/core';
import { TopNavigationBar } from '@/components/general/top-nav/TopNavigationBar';
import { Footer } from '@/components/general/footer/Footer';

export function DefaultLayout() {
  return (
    <Flex direction="column" style={{ minHeight: '100vh' }}>
      <TopNavigationBar />
      <Box component="main" style={{ flex: 1 }}>
        <Outlet />
      </Box>
      <Footer />
    </Flex>
  );
}
