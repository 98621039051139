import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ContentDTO, ContentRequest } from 'campaigner-client';
import { handleApiResponse } from '@/utils/api.utils';
import { contentApi } from '@/api/apiClient';

export const useUpdateContent = (campaignId: string, contentId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (updateData: ContentRequest) =>
      contentApi
        .updateContent(campaignId, contentId, updateData)
        .then((res) => handleApiResponse<ContentDTO>(res)),
    onSuccess: (updatedContent) => {
      queryClient.setQueryData(['campaigns', campaignId, 'content', contentId], updatedContent);
      queryClient.invalidateQueries({ queryKey: ['campaigns', campaignId, 'content'] });
    },
  });
};
