import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Notification, Skeleton, Text } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { ContentDTO } from 'campaigner-client';
import { ContentViewer } from '@/components/campaign/content-viewer/ContentViewer';
import { useContent } from '@/queries/contentQueries';
import { ContentEditor } from '@/components/campaign/content-editor/ContentEditor';
import { useUpdateContent } from '@/queries/content/mutations/update.content.mutation';
import { useCampaign } from '@/queries/campaignQueries';

const ContentPage: React.FC = () => {
  const { campaignId, contentId } = useParams<{ campaignId: string; contentId: string }>();
  const [isEditing, setIsEditing] = useState(false);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const { data: campaign } = useCampaign(campaignId!);

  const {
    data: fetchedContent,
    isLoading,
    error: contentError,
    refetch,
  } = useContent(campaignId!, contentId!);

  const [content, setContent] = useState<ContentDTO | null>(null);

  const { mutateAsync: updateContent, isPending: isUpdating } = useUpdateContent(
    campaignId || '',
    contentId || ''
  );

  const saveContent = async (newContent: Partial<ContentDTO>) => {
    if (!newContent.title || !newContent.content || !newContent.contentCategory) {
      console.error('Please ensure that the title/content/category are filled in before saving.');
      return;
    }

    try {
      await updateContent({
        content: newContent.content,
        title: newContent.title,
        contentCategory: newContent.contentCategory,
        metadata: newContent.metadata || {},
      });
      await refetch();
      setShowSuccessBanner(true);
      setTimeout(() => setShowSuccessBanner(false), 3000); // Hide banner after 3 seconds
    } catch (error) {
      console.error('Failed to update content:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (fetchedContent) {
      setContent(fetchedContent);
    }
  }, [fetchedContent]);

  useEffect(() => {
    setIsEditing(false);
  }, [campaignId, contentId]);

  if (!campaignId || !contentId) {
    return <Text>Invalid campaign or content ID</Text>;
  }

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (contentError) {
    return <Text>Error loading content: {contentError.message}</Text>;
  }

  if (!content) {
    return <Text>Content not found</Text>;
  }

  const handleCancel = () => {
    setIsEditing(false);
  };
  return (
    <>
      {showSuccessBanner && (
        <Notification
          icon={<IconCheck size="1.1rem" />}
          color="teal"
          title="Success"
          onClose={() => setShowSuccessBanner(false)}
        >
          Content saved successfully!
        </Notification>
      )}
      {isEditing ? (
        <ContentEditor
          initialContent={content}
          onSave={saveContent}
          onCancel={handleCancel}
          isUpdating={isUpdating}
        />
      ) : (
        <ContentViewer content={content} setIsEditing={setIsEditing} role={campaign?.role} />
      )}
    </>
  );
};

const LoadingSkeleton: React.FC = () => (
  <>
    {[...Array(8)].map((_, index) => (
      <Skeleton key={index} height={16} mt={index === 0 ? 0 : 6} radius="xl" />
    ))}
  </>
);

export default ContentPage;
