import React from 'react';
import { Button, Card, Center, Group, Select, SimpleGrid, Stack, Text } from '@mantine/core';
import { ContentPreviewDTO } from 'campaigner-client';
import { ContentCard } from '../content-card/ContentCard';

interface ContentGridProps {
  contents: ContentPreviewDTO[];
  campaignId: string;
  loadMore: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  sortOption: string;
  onSortChange: (value: string | null) => void;
}

export function ContentGrid({
  contents,
  campaignId,
  loadMore,
  hasNextPage,
  isFetchingNextPage,
  sortOption,
  onSortChange,
}: ContentGridProps) {
  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Stack>
        <Group>
          <Text size="xl">Content</Text>
          <Select
            value={sortOption}
            onChange={onSortChange}
            data={[
              {
                value: 'alphabetical',
                label: 'Alphabetical',
              },
              {
                value: 'updatedAt_desc',
                label: 'Last Updated (Newest First)',
              },
              {
                value: 'updatedAt_asc',
                label: 'Last Updated (Oldest First)',
              },
              {
                value: 'createdAt_desc',
                label: 'Created (Newest First)',
              },
              {
                value: 'createdAt_asc',
                label: 'Created (Oldest First)',
              },
            ]}
            style={{ width: 250 }}
          />
        </Group>
        <SimpleGrid
          cols={{
            base: 1,
            sm: 2,
            lg: 3,
          }}
          spacing={{
            base: 'sm',
            lg: 'lg',
          }}
          verticalSpacing={{
            base: 'md',
            sm: 'xl',
          }}
        >
          {contents.map((content) => (
            <ContentCard key={content.id} content={content} campaignId={campaignId} />
          ))}
        </SimpleGrid>
        {hasNextPage && (
          <Center mt="xl">
            <Button onClick={() => loadMore()} loading={isFetchingNextPage}>
              {isFetchingNextPage ? 'Loading more...' : 'Load more'}
            </Button>
          </Center>
        )}
      </Stack>
    </Card>
  );
}
