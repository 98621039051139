import axios, { AxiosInstance } from 'axios';
import {
  CampaignControllerApi,
  Configuration,
  ContentControllerApi,
  InviteLinkControllerApi,
  ProfileControllerApi,
} from 'campaigner-client';
import { apiUrl } from '@/config/environment.config';
import { getSupabaseSession, supabase } from '@/api/supabaseClient';

const apiClient: AxiosInstance = axios.create({
  baseURL: apiUrl,
});

apiClient.interceptors.request.use(
  async (config) => {
    const session = await getSupabaseSession();
    if (session) {
      config.headers.Authorization = `Bearer ${session.access_token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const { data, error: refreshError } = await supabase.auth.refreshSession();
      if (refreshError) {
        // Handle refresh error (e.g., redirect to login)
        return Promise.reject(refreshError);
      }
      if (data.session) {
        originalRequest.headers.Authorization = `Bearer ${data.session.access_token}`;
        return apiClient(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

const configuration = new Configuration({
  basePath: apiClient.defaults.baseURL,
});

export const campaignApi = new CampaignControllerApi(configuration, undefined, apiClient);
export const contentApi = new ContentControllerApi(configuration, undefined, apiClient);
export const profileApi = new ProfileControllerApi(configuration, undefined, apiClient);
export const inviteLinkApi = new InviteLinkControllerApi(configuration, undefined, apiClient);
