import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { spotlight } from '@mantine/spotlight';
import { CampaignDTO, ContentPreviewDTO } from 'campaigner-client';
import { useMediaQuery } from '@mantine/hooks';
import { useAuth } from '@/hooks/useAuth';

export function useCampaignShell(campaign: CampaignDTO, contentPreviews: ContentPreviewDTO[]) {
  const { profile, logout } = useAuth();
  const navigate = useNavigate();
  const [mobileOpened, setMobileOpened] = useState(false);
  const [desktopExpanded, setDesktopExpanded] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (!isMobile) {
      setMobileOpened(false);
    }
  }, [isMobile]);

  const [mapPreviews] = useState(() =>
    contentPreviews.filter((preview) => preview.contentCategory === 'MAP')
  );

  const [textContentPreviews] = useState(() =>
    contentPreviews.filter(
      (preview) => !(preview.contentCategory === 'MAP' || preview.contentCategory === 'PICTURE')
    )
  );

  const handleLogout = async () => {
    logout();
    navigate('/');
  };

  const handleSearch = () => {
    spotlight.open();
  };

  const navigateTo = (path: string) => {
    navigate(path);
    if (isMobile) {
      setMobileOpened(false);
    }
  };

  return {
    profile,
    isMobile,
    mobileOpened,
    setMobileOpened,
    desktopExpanded,
    setDesktopExpanded,
    mapPreviews,
    textContentPreviews,
    handleLogout,
    handleSearch,
    navigateTo,
  };
}
