import { useEffect, useState } from 'react';
import { ArcDTO, ContentRequest, ObjectiveDTO, QuestDTO, QuestLogDTO } from 'campaigner-client';
import { useQuestLogContent } from '@/queries/contentQueries';
import { useUpdateContent } from '@/queries/content/mutations/update.content.mutation';
import { useCreateContent } from '@/queries/content/mutations/create.content.mutation';

interface UseQuestLogProps {
  campaignId: string;
}

export function useQuestLog({ campaignId }: UseQuestLogProps) {
  const { data, isLoading } = useQuestLogContent(campaignId);
  const [questLog, setQuestLog] = useState<QuestLogDTO>();
  const [questLogContentId, setQuestLogContentId] = useState<string>();
  useEffect(() => {
    if (data && data.contentCategory === 'QUEST') {
      setQuestLog(JSON.parse(data.content) as QuestLogDTO);
      setQuestLogContentId(data.id);
    }
  }, [data]);

  const { mutateAsync: updateContent } = useUpdateContent(campaignId!, questLogContentId || '');
  const { mutateAsync: createContent } = useCreateContent(campaignId!);
  const updateQuestLog = (log: QuestLogDTO) => {
    if (!data) {
      createContent({
        title: 'Quest Log',
        contentCategory: 'QUEST',
        content: JSON.stringify(log),
        metadata: {},
      })
        .then((response) => {
          setQuestLog(log);
          setQuestLogContentId(response.id);
        })
        .catch((err) => console.error('Unable to create quest log.', err));
      return;
    }

    const updatedQuestLog: ContentRequest = {
      content: JSON.stringify(log),
      contentCategory: 'QUEST',
      title: data.title,
      metadata: data.metadata,
    };
    updateContent(updatedQuestLog)
      .then(() => {
        setQuestLog(log);
      })
      .catch((err) => console.error('Unable to update quest log.', err));
  };

  const putQuest = (arcId: string, quest: QuestDTO) => {
    if (!questLog) return;
    let updatedQuestLog: QuestLogDTO;
    if (arcId === 'MISC') {
      updatedQuestLog = {
        ...questLog,
        miscQuests: questLog.miscQuests.map((q) => (q.id === quest.id ? quest : q)),
      };
      if (!updatedQuestLog.miscQuests.some((q) => q.id === quest.id)) {
        updatedQuestLog.miscQuests.push(quest);
      }
    } else {
      updatedQuestLog = {
        ...questLog,
        arcs: questLog.arcs.map((arc) => {
          if (arc.id === arcId) {
            const updatedQuests = arc.quests.map((q) => (q.id === quest.id ? quest : q));
            if (!updatedQuests.some((q) => q.id === quest.id)) {
              updatedQuests.push(quest);
            }
            return {
              ...arc,
              quests: updatedQuests,
            };
          }
          return arc;
        }),
      };
    }
    updateQuestLog(updatedQuestLog);
  };

  const putArc = (arc: ArcDTO) => {
    if (!questLog) return;
    const updatedQuestLog: QuestLogDTO = {
      ...questLog,
      arcs: questLog.arcs.map((a) => (a.id === arc.id ? arc : a)),
    };
    if (!updatedQuestLog.arcs.some((a) => a.id === arc.id)) {
      updatedQuestLog.arcs.push(arc);
    }
    updateQuestLog(updatedQuestLog);
  };

  const putObjective = (arcId: string, questId: string, objective: ObjectiveDTO) => {
    if (!questLog) return;
    let updatedQuestLog: QuestLogDTO;
    if (arcId === 'MISC') {
      updatedQuestLog = {
        ...questLog,
        miscQuests: questLog.miscQuests.map((q) => {
          if (q.id === questId) {
            const updatedObjectives = q.objectives.map((o) =>
              o.id === objective.id ? objective : o
            );
            if (!updatedObjectives.some((o) => o.id === objective.id)) {
              updatedObjectives.push(objective);
            }
            return {
              ...q,
              objectives: updatedObjectives,
            };
          }
          return q;
        }),
      };
    } else {
      updatedQuestLog = {
        ...questLog,
        arcs: questLog.arcs.map((arc) => {
          if (arc.id === arcId) {
            const updatedQuests = arc.quests.map((q) => {
              if (q.id === questId) {
                const updatedObjectives = q.objectives.map((o) =>
                  o.id === objective.id ? objective : o
                );
                if (!updatedObjectives.some((o) => o.id === objective.id)) {
                  updatedObjectives.push(objective);
                }
                return {
                  ...q,
                  objectives: updatedObjectives,
                };
              }
              return q;
            });
            return {
              ...arc,
              quests: updatedQuests,
            };
          }
          return arc;
        }),
      };
    }
    updateQuestLog(updatedQuestLog);
  };

  const deleteQuest = (arcId: string, questId: string) => {
    if (!questLog) return;
    let updatedQuestLog: QuestLogDTO;
    if (arcId === 'MISC') {
      updatedQuestLog = {
        ...questLog,
        miscQuests: questLog.miscQuests.filter((q) => q.id !== questId),
      };
    } else {
      updatedQuestLog = {
        ...questLog,
        arcs: questLog.arcs.map((arc) => {
          if (arc.id === arcId) {
            return {
              ...arc,
              quests: arc.quests.filter((q) => q.id !== questId),
            };
          }
          return arc;
        }),
      };
    }
    updateQuestLog(updatedQuestLog);
  };

  const deleteArc = (arcId: string) => {
    if (!questLog) return;
    const updatedQuestLog: QuestLogDTO = {
      ...questLog,
      arcs: questLog.arcs.filter((a) => a.id !== arcId),
    };
    updateQuestLog(updatedQuestLog);
  };

  const deleteObjective = (arcId: string, questId: string, objectiveId: string) => {
    if (!questLog) return;
    let updatedQuestLog: QuestLogDTO;
    if (arcId === 'MISC') {
      updatedQuestLog = {
        ...questLog,
        miscQuests: questLog.miscQuests.map((q) => {
          if (q.id === questId) {
            return {
              ...q,
              objectives: q.objectives.filter((o) => o.id !== objectiveId),
            };
          }
          return q;
        }),
      };
    } else {
      updatedQuestLog = {
        ...questLog,
        arcs: questLog.arcs.map((arc) => {
          if (arc.id === arcId) {
            const updatedQuests = arc.quests.map((q) => {
              if (q.id === questId) {
                return {
                  ...q,
                  objectives: q.objectives.filter((o) => o.id !== objectiveId),
                };
              }
              return q;
            });
            return {
              ...arc,
              quests: updatedQuests,
            };
          }
          return arc;
        }),
      };
    }
    updateQuestLog(updatedQuestLog);
  };

  return {
    questLog,
    isLoading,
    putQuest,
    putArc,
    putObjective,
    deleteQuest,
    deleteArc,
    deleteObjective,
  };
}
