import React from 'react';
import { Button, Modal, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ObjectiveDTO } from 'campaigner-client';

interface NewObjectiveModalProps {
  opened: boolean;
  onClose: () => void;
  onAddObjective: (newObjective: ObjectiveDTO) => void;
}

export function NewObjectiveModal({ opened, onClose, onAddObjective }: NewObjectiveModalProps) {
  const form = useForm({
    initialValues: {
      description: '',
    },
    validate: {
      description: (value) => (value.trim().length > 0 ? null : 'Description is required'),
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    onAddObjective({
      id: `objective-${Date.now()}`,
      description: values.description,
      completed: false,
    });
    form.reset();
    onClose();
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Add New Objective">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <TextInput label="Description" required {...form.getInputProps('description')} />
          <Button type="submit">Add Objective</Button>
        </Stack>
      </form>
    </Modal>
  );
}
