import React from 'react';
import { useForm } from '@mantine/form';
import {
  Button,
  Center,
  Container,
  Paper,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconSword, IconWand } from '@tabler/icons-react';
import { useCreateProfile } from '@/queries/profileQueries';
import { useAuth } from '@/hooks/useAuth';

export function CompleteProfilePage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { mutateAsync: createProfile } = useCreateProfile();

  const form = useForm({
    initialValues: {
      username: '',
      name: '',
    },
    validate: {
      username: (value) =>
        value.length < 3 ? 'Username must be at least 3 characters long' : null,
      name: (value) => (value.length < 2 ? 'Name must be at least 2 characters long' : null),
    },
  });

  const handleSubmit = async (values: typeof form.values) => {
    await createProfile({
      username: values.username,
      name: values.name,
      email: user?.email || '',
    })
      .then(() => navigate('/campaigns'))
      .catch((err) => {
        console.error(err);
        navigate('/');
      });
  };

  return (
    <Center
      style={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <Container size="md" my={40}>
        <Paper radius="md" p="xl" withBorder>
          <Stack align="center" gap="xl">
            <ThemeIcon size={80} radius="xl" variant="light" color="blue">
              <IconWand size={50} stroke={1.5} />
            </ThemeIcon>
            <Title order={1} ta="center" size="h2">
              Almost There, Brave Adventurer!
            </Title>
            <Text size="lg" ta="center" c="dimmed" maw={520}>
              Greetings! You&apos;re just a spell away from embarking on epic campaign management.
              Let&apos;s craft your legendary profile!
            </Text>

            <form
              onSubmit={form.onSubmit(handleSubmit)}
              style={{
                width: '100%',
                maxWidth: 400,
              }}
            >
              <Stack>
                <TextInput
                  required
                  label="Choose Your Hero Name (Username)"
                  placeholder="e.g., MightyDM, QuestMaster"
                  leftSection={<IconSword size="1rem" />}
                  {...form.getInputProps('username')}
                />
                <TextInput
                  required
                  label="Your True Identity (Full Name)"
                  placeholder="As known in the mortal realm"
                  leftSection={<IconWand size="1rem" />}
                  {...form.getInputProps('name')}
                />
                <Button type="submit" size="lg" fullWidth mt="xl">
                  Forge My Legend
                </Button>
              </Stack>
            </form>

            <Text size="sm" ta="center" c="dimmed" mt="sm">
              By completing your profile, you&apos;re one step closer to slaying campaign chaos and
              becoming a true Dungeon Master extraordinaire!
            </Text>
          </Stack>
        </Paper>
      </Container>
    </Center>
  );
}
