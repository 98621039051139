import { useQuery } from '@tanstack/react-query';
import { CampaignMembers } from 'campaigner-client';
import { handleApiResponse } from '@/utils/api.utils';
import { campaignApi } from '@/api/apiClient';

export const useCampaignMembers = (campaignId: string) =>
  useQuery({
    queryKey: ['campaigns', campaignId, 'members'],
    queryFn: async () =>
      campaignApi
        .getCampaignMembers(campaignId)
        .then((response) => handleApiResponse<CampaignMembers>(response)),
  });
