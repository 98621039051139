import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Container,
  CopyButton,
  Group,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { IconCopy, IconTrash, IconUserPlus } from '@tabler/icons-react';
import { modals } from '@mantine/modals';
import { CampaignDTORoleEnum, ProfileDTO } from 'campaigner-client';
import { useCampaignMembers } from '@/queries/campaignMemberQueries';
import { useGenerateInviteLink } from '@/queries/campaignInviteMutations';
import { useRemoveMember } from '@/queries/campaignMemberMutations';
import { toTitleCase } from '@/utils/string.utils';

interface ManageCampaignProps {
  campaignId: string;
  baseUrl: string;
}

const getRoleBadgeProps = (role: CampaignDTORoleEnum) => {
  switch (role) {
    case 'ADMIN':
      return {
        color: 'accent',
        variant: 'light',
      };
    case 'AUTHOR':
      return {
        color: 'brand-teal',
        variant: 'light',
      };
    case 'MEMBER':
      return {
        color: 'brand-orange',
        variant: 'light',
      };
    default:
      return {
        color: 'gray',
        variant: 'outline',
      };
  }
};

export function ManageCampaign({ campaignId, baseUrl }: ManageCampaignProps) {
  const { data: campaignMembers } = useCampaignMembers(campaignId);
  const { mutateAsync: generateInvite } = useGenerateInviteLink();
  const { mutateAsync: removeMember } = useRemoveMember();

  const [inviteRole, setInviteRole] = useState<CampaignDTORoleEnum>('MEMBER');
  const [inviteLink, setInviteLink] = useState('');
  const [membersArray, setMembersArray] = useState<ProfileDTO[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerateLink = () => {
    setIsLoading(true);
    generateInvite({
      campaignId,
      role: inviteRole,
      durationInDays: 1,
    }).then((invite) => {
      setInviteLink(`${baseUrl}/campaign/${campaignId}/invite/${invite.id}`);
      setInviteRole(invite.role);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (campaignMembers && campaignMembers.members) {
      setMembersArray(campaignMembers.members);
    }
  }, [campaignMembers]);

  return (
    <Container>
      <Text size="xl" w={500} mb="md">
        Manage Campaign
      </Text>

      <Card shadow="xs" padding="md" radius="md" withBorder mb="lg">
        <Text size="lg" w={500} mb="xs">
          Invite New Members
        </Text>
        <Group align="flex-end">
          <Select
            label="Invite Role"
            value={inviteRole}
            onChange={(value) => setInviteRole(value as CampaignDTORoleEnum)}
            data={[
              {
                value: 'ADMIN',
                label: 'Admin',
              },
              {
                value: 'AUTHOR',
                label: 'Author',
              },
              {
                value: 'MEMBER',
                label: 'Member',
              },
            ]}
          />
          <Button
            leftSection={<IconUserPlus size="1rem" />}
            onClick={handleGenerateLink}
            loading={isLoading}
          >
            Generate Invite Link
          </Button>
        </Group>
        {inviteLink && (
          <Group mt="md" align="center">
            <TextInput value={inviteLink} readOnly style={{ flexGrow: 1 }} />
            <CopyButton value={inviteLink}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                  <Button color={copied ? 'teal' : 'blue'} onClick={copy}>
                    <IconCopy size="1rem" />
                  </Button>
                </Tooltip>
              )}
            </CopyButton>
          </Group>
        )}
      </Card>

      <Card shadow="xs" padding="md" radius="md" withBorder>
        <Text size="lg" w={500} mb="xs">
          Current Members
        </Text>
        <Table highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Role</Table.Th>
              <Table.Th>Actions</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {membersArray.map((member) => (
              <Table.Tr key={member.id}>
                <Table.Td>{member.name}</Table.Td>
                <Table.Td>
                  <Badge {...getRoleBadgeProps(member.roles[campaignId] as CampaignDTORoleEnum)}>
                    {String(toTitleCase(member.roles[campaignId]))}
                  </Badge>
                </Table.Td>
                <Table.Td>
                  <Button
                    variant="outline"
                    color="red"
                    size="xs"
                    leftSection={<IconTrash size="1rem" />}
                    onClick={async () => {
                      modals.openConfirmModal({
                        title: 'Remove Member',
                        centered: true,
                        children: (
                          <Text size="sm">
                            Are you sure you want to remove {member.name} from this Campaign?
                          </Text>
                        ),
                        labels: {
                          confirm: 'Remove member',
                          cancel: "No don't remove them",
                        },
                        confirmProps: { color: 'red' },
                        onConfirm: () =>
                          removeMember({
                            campaignId,
                            profileId: member.id,
                          }),
                      });
                    }}
                  >
                    Remove
                  </Button>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Card>
    </Container>
  );
}
