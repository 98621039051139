import { Button } from '@mantine/core';
import { QuestLogDTO } from 'campaigner-client';
import { useCreateContent } from '@/queries/content/mutations/create.content.mutation';

const sampleQuestLog: QuestLogDTO = {
  arcs: [
    {
      id: 'arc1',
      name: 'The Darkening Skies',
      description: 'A shadow looms over the land as an ancient evil awakens.',
      quests: [
        {
          id: 'quest1',
          name: 'Investigate the Abandoned Tower',
          description: "Strange lights have been seen coming from the old wizard's tower.",
          status: 'ACTIVE',
          objectives: [
            {
              id: 'obj1',
              description: 'Reach the tower',
              completed: false,
            },
            {
              id: 'obj2',
              description: 'Find clues about the lights',
              completed: false,
            },
          ],
        },
      ],
    },
  ],
  miscQuests: [
    {
      id: 'misc1',
      name: 'Help the Farmer',
      description: 'A local farmer needs help with a pest problem.',
      status: 'ACTIVE',
      objectives: [
        {
          id: 'miscObj1',
          description: 'Talk to the farmer',
          completed: true,
        },
        {
          id: 'miscObj2',
          description: 'Clear the field of giant rats',
          completed: false,
        },
      ],
    },
  ],
};

export function TestPage() {
  const { mutateAsync: createContent } = useCreateContent('ab562220-2c16-4d84-8672-58e8395b83eb');

  const handleSave = async () => {
    await createContent({
      title: 'Quest Log',
      contentCategory: 'QUEST',
      content: JSON.stringify(sampleQuestLog),
      metadata: {},
    });
  };

  return <Button onClick={() => handleSave()}>Load Up</Button>;
}
