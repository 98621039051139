import { Outlet, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { SpotlightActionData } from '@mantine/spotlight';
import { modals } from '@mantine/modals';
import { CampaignDTO, ContentPreviewDTO } from 'campaigner-client';
import { LoadingOverlay } from '@mantine/core';
import { CampaignAppShell } from '@/components/campaign/app-shell/CampaignAppShell';
import { useCampaigns } from '@/queries/campaignQueries';
import { useContents } from '@/queries/contentQueries';
import { useSpotlightManager } from '@/hooks/useSpotlightManager';
import { ContentUploader } from '@/components/campaign/content-uploader/ContentUploader';

export function CampaignLayout() {
  const { campaignId } = useParams<{ campaignId: string }>();
  const { data: campaigns, isLoading } = useCampaigns();
  const [campaign, setCampaign] = useState<CampaignDTO | undefined>();
  const navigate = useNavigate();
  const { addContextActions, removeContextActions } = useSpotlightManager();
  const {
    data: contentData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useContents(campaignId || '');
  const [allContentPreviews, setAllContentPreviews] = useState<ContentPreviewDTO[]>([]);

  useEffect(() => {
    if (campaigns) {
      setCampaign(campaigns.find((c) => c.id === campaignId));
    }
  }, [campaignId, campaigns]);

  useEffect(() => {
    if (contentData) {
      const newContentPreviews = contentData.pages.flatMap((page) => page.items);
      setAllContentPreviews((prevPreviews) => {
        const uniquePreviews = [...prevPreviews];
        newContentPreviews.forEach((preview) => {
          if (!uniquePreviews.some((p) => p.id === preview.id)) {
            uniquePreviews.push(preview);
          }
        });
        return uniquePreviews;
      });
    }
  }, [contentData]);

  const contentPreviews = useMemo(
    () => allContentPreviews.filter((preview) => preview.contentCategory !== 'QUEST'),
    [allContentPreviews]
  );

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const openUploadModal = () => {
    if (campaignId) {
      modals.open({
        title: 'Upload New Content',
        size: 'lg',
        children: <ContentUploader campaignId={campaignId} />,
      });
    }
  };
  useEffect(() => {
    const campaignActions: SpotlightActionData[] = [
      {
        id: 'campaign-dashboard',
        label: 'Campaign Dashboard',
        description: 'Go to the campaign dashboard',
        onClick: () => navigate(`/campaign/${campaignId}`),
      },
      {
        id: 'campaign-content',
        label: 'Campaign Content',
        description: 'Manage campaign content',
        onClick: () => navigate(`/campaign/${campaignId}/content`),
      },
    ];
    addContextActions(campaignActions);
    if (campaign?.role === 'ADMIN' || campaign?.role === 'AUTHOR') {
      addContextActions([
        {
          id: 'create-campaign-content',
          label: 'Create Content',
          description: 'Create new campaign content',
          onClick: () => navigate(`/campaign/${campaignId}/content/create`),
        },
        {
          id: 'upload-campaign-content',
          label: 'Upload Content',
          description: 'Upload campaign content',
          onClick: () => openUploadModal(),
        },
      ]);
    }

    return () => {
      removeContextActions(campaignActions.map((action) => action.id));
    };
  }, [campaignId, campaign]);

  if (isLoading) {
    return <LoadingOverlay visible={isLoading} />;
  }
  if (!campaignId || !campaign) {
    navigate('/');
    return null;
  }

  return (
    <CampaignAppShell campaign={campaign} contentPreviews={contentPreviews}>
      <Outlet />
    </CampaignAppShell>
  );
}
