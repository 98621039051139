import { Avatar, Badge, Group, Table, Text, UnstyledButton } from '@mantine/core';
import { CampaignDTO } from 'campaigner-client';
import React from 'react';
import { Link } from 'react-router-dom';

interface CampaignsTableProps {
  campaigns: CampaignDTO[];
}

export function CampaignsTable({ campaigns }: CampaignsTableProps) {
  const rows = campaigns.map((campaign) => (
    <Table.Tr key={campaign.id}>
      <Table.Td>
        <UnstyledButton component={Link} to={`/campaign/${campaign.id}`}>
          <Group gap="sm">
            <Avatar
              size={40}
              radius={40}
              src={`https://api.dicebear.com/6.x/initials/svg?seed=${campaign.name}`}
            />
            <div>
              <Text fz="sm" fw={500}>
                {campaign.name}
              </Text>
              <Text fz="xs" c="dimmed">
                {campaign.description}
              </Text>
            </div>
          </Group>
        </UnstyledButton>
      </Table.Td>
      <Table.Td>
        <Badge fullWidth variant="light" color="green">
          In Progress
        </Badge>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Table.ScrollContainer minWidth={800}>
      <Table verticalSpacing="sm">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Campaign</Table.Th>
            <Table.Th>Status</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}
