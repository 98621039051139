import React from 'react';
import { Box, Button, Container, Stack, Text, Title } from '@mantine/core';
import { Link } from 'react-router-dom';
import { BackgroundSlider } from '@/components/utils/background-slider/BackgroundSlider';

const backgroundImages = ['bg-1.webp', 'bg-2.webp', 'bg-3.webp'];

export function HomePage() {
  return (
    <Container size="xl">
      <BackgroundSlider images={backgroundImages} />

      <Box>
        <Container size="lg">
          <Stack align="center" gap="xl" style={{ textAlign: 'center' }}>
            <Title order={1} size={48} c="white" style={{ maxWidth: '800px' }}>
              The Ultimate TTRPG Campaign Management Tool
            </Title>
            <Text size="xl" c="gray.3" style={{ maxWidth: '600px' }}>
              Streamline your tabletop RPG campaigns with The Campaigner. Our platform offers
              intuitive tools for organization, player collaboration, and AI-assisted content
              creation – everything a modern Dungeon Master needs.
            </Text>
            <Button
              component={Link}
              to="/login"
              size="xl"
              color="brand-orange"
              style={{
                marginTop: '2rem',
                background:
                  'linear-gradient(45deg, var(--mantine-color-brand-orange-6), var(--mantine-color-accent-5))',
              }}
            >
              Get started
            </Button>
          </Stack>
        </Container>
      </Box>
    </Container>
  );
}
