import { useState } from 'react';
import { ContentFormat } from '@/types/alias.types';
import { useCreateContent } from '@/queries/content/mutations/create.content.mutation';

export function useContentCreation(campaignId: string) {
  const [title, setTitle] = useState('');
  const [contentType, setContentType] = useState<ContentFormat>();
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: createContent } = useCreateContent(campaignId);

  const handleCreateContent = async (content: string) => {
    if (!contentType) return;
    setIsLoading(true);
    try {
      await createContent({
        title,
        content,
        contentCategory: contentType,
        metadata: {},
      });
      // Reset form
      setTitle('');
      setContentType(undefined);
    } catch (error) {
      console.error('Error creating content:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    title,
    setTitle,
    contentType,
    setContentType,
    isLoading,
    handleCreateContent,
  };
}
