import { Outlet, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { SpotlightActionData } from '@mantine/spotlight';
import { IconHome, IconList, IconPlus, IconUser } from '@tabler/icons-react';
import { Box, Flex, rem } from '@mantine/core';
import { TopNavigationBar } from '@/components/general/top-nav/TopNavigationBar';
import { useCampaigns } from '@/queries/campaignQueries';
import { useSpotlightManager } from '@/hooks/useSpotlightManager';
import { useAuthInitialization } from '@/queries/authQueries';
import { useAuth } from '@/hooks/useAuth';
import { Footer } from '@/components/general/footer/Footer';

export function ProtectedLayout() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { isLoading } = useAuthInitialization();
  const { data: campaigns } = useCampaigns();
  const { addBaseActions, addAuthActions, addCampaignActions } = useSpotlightManager();

  useEffect(() => {
    const baseActions: SpotlightActionData[] = [
      {
        id: 'create-campaign',
        label: 'Create New Campaign',
        description: 'Start a new campaign from scratch',
        onClick: () => navigate('/campaigns/new'),
        leftSection: (
          <IconPlus
            style={{
              width: rem(24),
              height: rem(24),
            }}
            stroke={1.5}
          />
        ),
      },
      {
        id: 'all-campaigns',
        label: 'See All Campaigns',
        description: 'View a list of all your campaigns',
        onClick: () => navigate('/campaigns'),
        leftSection: (
          <IconList
            style={{
              width: rem(24),
              height: rem(24),
            }}
            stroke={1.5}
          />
        ),
      },
    ];

    addBaseActions(baseActions);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const authActions: SpotlightActionData[] = [
        {
          id: 'profile',
          label: 'Go to Profile',
          description: 'View and edit your user profile',
          onClick: () => navigate('/profile'),
          leftSection: (
            <IconUser
              style={{
                width: rem(24),
                height: rem(24),
              }}
              stroke={1.5}
            />
          ),
        },
      ];

      addAuthActions(authActions, isAuthenticated);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const campaignActions =
      campaigns?.map((campaign) => ({
        id: `campaign-${campaign.id}`,
        label: `Go to ${campaign.name}`,
        description: `Navigate to the ${campaign.name} campaign`,
        onClick: () => navigate(`/campaign/${campaign.id}`),
        leftSection: (
          <IconHome
            style={{
              width: rem(24),
              height: rem(24),
            }}
            stroke={1.5}
          />
        ),
      })) || [];

    addCampaignActions(campaignActions);
  }, [campaigns]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Flex direction="column" style={{ minHeight: '100vh' }}>
      <TopNavigationBar />
      <Box component="main" style={{ flex: 1 }}>
        <Outlet />
      </Box>
      <Footer />
    </Flex>
  );
}
