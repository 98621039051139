import '@mantine/core/styles.css';
import '@mantine/spotlight/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/nprogress/styles.css';
import '@mantine/tiptap/styles.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';

import { CookiesProvider } from 'react-cookie';
import { NavigationProgress } from '@mantine/nprogress';
import { Router } from './Router';
import './globals.css';
import { theme } from './theme';
import { GlobalSpotlight } from '@/components/general/global-spotlight/GlobalSpotlight';
import { useErrorStore } from '@/state/errorStore';
import { GlobalErrorDisplay } from '@/components/error/GlobalErrorDisplay';

const queryClient = new QueryClient();

export default function App() {
  const { error, clearError } = useErrorStore();

  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <MantineProvider theme={theme} forceColorScheme="dark">
        <NavigationProgress />
        <QueryClientProvider client={queryClient}>
          <ModalsProvider>
            <GlobalSpotlight />
            <GlobalErrorDisplay error={error} onClose={clearError} />
            <Router />
          </ModalsProvider>
        </QueryClientProvider>
      </MantineProvider>
    </CookiesProvider>
  );
}
