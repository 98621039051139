import React, { useEffect, useState } from 'react';
import { Accordion, Button, Container, Group, Stack, Switch, Text, Title } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { CampaignDTO } from 'campaigner-client';
import { ArcItem } from '@/components/campaign/quest-log/ArcItem';
import { MiscQuestsItem } from '@/components/campaign/quest-log/MiscQuestsItem';
import { NewArcModal } from '@/components/campaign/quest-log/NewArcModal';
import { NewQuestModal } from '@/components/campaign/quest-log/NewQuestModal';
import { useQuestLog } from '@/hooks/useQuestLog';
import { useCampaigns } from '@/queries/campaignQueries';

export function QuestLogPage() {
  const { campaignId } = useParams<{ campaignId: string }>();
  const { questLog, isLoading, putQuest, putArc } = useQuestLog({
    campaignId: campaignId!,
  });
  const { data: campaigns } = useCampaigns();
  const [campaign, setCampaign] = useState<CampaignDTO | undefined>();

  const [editMode, setEditMode] = useState(false);
  const [newArcModal, setNewArcModal] = useState(false);
  const [newQuestModal, setNewQuestModal] = useState(false);

  useEffect(() => {
    if (campaigns) {
      setCampaign(campaigns.find((c) => c.id === campaignId));
    }
  }, [campaignId, campaigns]);

  if (isLoading || !campaign || !questLog) return <Text>Loading quest log...</Text>;

  return (
    <Container size="lg">
      <Group mb="md">
        <Title order={1}>Quest Log</Title>
        {campaign.role === 'ADMIN' && (
          <Switch
            label="Edit Mode"
            checked={editMode}
            onChange={(event) => setEditMode(event.currentTarget.checked)}
          />
        )}
      </Group>
      <Accordion multiple>
        {questLog?.arcs.map((arc) => (
          <ArcItem
            key={arc.id}
            arc={arc}
            editMode={editMode}
            onAddQuest={() => setNewArcModal(true)}
            onUpdateArc={putArc}
          />
        ))}
        <MiscQuestsItem
          miscQuests={questLog?.miscQuests || []}
          editMode={editMode}
          onAddQuest={() => setNewQuestModal(true)}
          onUpdateQuest={(quest) => putQuest('MISC', quest)}
        />
      </Accordion>
      {editMode && (
        <Stack mt="md">
          <Button leftSection={<IconPlus size={14} />} onClick={() => setNewArcModal(true)}>
            Add New Arc
          </Button>
        </Stack>
      )}

      <NewArcModal opened={newArcModal} onClose={() => setNewArcModal(false)} onAddArc={putArc} />
      <NewQuestModal
        opened={newQuestModal}
        onClose={() => setNewQuestModal(false)}
        onAddQuest={(newQuest) => putQuest('', newQuest)}
      />
    </Container>
  );
}

export default QuestLogPage;
