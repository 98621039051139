import React from 'react';
import { Button, Modal, Stack, Textarea, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ArcDTO } from 'campaigner-client';

interface NewArcModalProps {
  opened: boolean;
  onClose: () => void;
  onAddArc: (newArc: ArcDTO) => void;
}

export function NewArcModal({ opened, onClose, onAddArc }: NewArcModalProps) {
  const form = useForm({
    initialValues: {
      name: '',
      description: '',
    },
    validate: {
      name: (value) => (value.trim().length > 0 ? null : 'Name is required'),
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    onAddArc({
      id: `arc-${Date.now()}`,
      name: values.name,
      description: values.description,
      quests: [],
    });
    form.reset();
    onClose();
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Add New Arc">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <TextInput label="Name" required {...form.getInputProps('name')} />
          <Textarea label="Description" {...form.getInputProps('description')} />
          <Button type="submit">Add Arc</Button>
        </Stack>
      </form>
    </Modal>
  );
}
