import React, { useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Box, Loader, Text } from '@mantine/core';

export interface MapViewerProps {
  url?: string;
}

export default function MapViewer({ url }: MapViewerProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setError('Failed to load the map. Please try again later.');
  };

  if (!url) {
    return <Text>No map URL provided.</Text>;
  }

  return (
    <Box
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      {isLoading && <Loader size="xl" />}
      {error && <Text color="red">{error}</Text>}
      <TransformWrapper>
        <TransformComponent>
          <img
            src={url}
            alt="Map"
            style={{
              width: '100vh',
              height: '100vh',
              objectFit: 'contain',
              display: isLoading ? 'none' : 'block',
            }}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        </TransformComponent>
      </TransformWrapper>
    </Box>
  );
}
