import { ContentFormat } from '@/types/alias.types';

const MIME_TYPE_MAPPING: { [key: string]: ContentFormat } = {
  'text/markdown': 'LORE',
  'image/jpeg': 'PICTURE',
  'image/png': 'PICTURE',
  'image/gif': 'PICTURE',
  'image/webp': 'PICTURE',
  'image/svg+xml': 'PICTURE',
};

const EXTENSION_MAPPING: { [key: string]: ContentFormat } = {
  md: 'LORE',
  markdown: 'LORE',
  jpg: 'PICTURE',
  jpeg: 'PICTURE',
  png: 'PICTURE',
  gif: 'PICTURE',
  svg: 'PICTURE',
};

export async function detectContentType(file: File): Promise<ContentFormat> {
  // Step 1: Check MIME type
  const mimeType = file.type;
  if (mimeType && MIME_TYPE_MAPPING[mimeType]) {
    return MIME_TYPE_MAPPING[mimeType];
  }

  // Step 2: Check file extension
  const extension = file.name.split('.').pop()?.toLowerCase();
  if (extension && EXTENSION_MAPPING[extension]) {
    return EXTENSION_MAPPING[extension];
  }

  // Step 3: Check magic numbers for specific file types
  const buffer = await readFileAsArrayBuffer(file);
  const uint8Array = new Uint8Array(buffer);

  // Check for PNG
  if (
    uint8Array[0] === 0x89 &&
    uint8Array[1] === 0x50 &&
    uint8Array[2] === 0x4e &&
    uint8Array[3] === 0x47
  ) {
    return 'PICTURE';
  }

  // Check for JPEG
  if (uint8Array[0] === 0xff && uint8Array[1] === 0xd8 && uint8Array[2] === 0xff) {
    return 'PICTURE';
  }

  // Add more magic number checks for other file types as needed

  // If all else fails, return a default type
  return 'LORE';
}

function readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target?.result as ArrayBuffer);
    reader.onerror = (e) => reject(e);
    reader.readAsArrayBuffer(file);
  });
}

export function encodeImageToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export const readFileAsText = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target?.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsText(file);
  });
