import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import React from 'react';
import { HomePage } from './pages/home/Home.page';
import LoginPage from '@/pages/login/Login.page';
import ConfirmProfilePage from '@/pages/confirm-profile/ConfirmProfile.page';
import ContentPage from '@/pages/campaign-content/CampaignContent.page';
import CampaignHomePage from '@/pages/campaign-home/CampaignHome.page';
import { CampaignLayout } from '@/components/layouts/campaign-layout/CampaignLayout';
import CampaignQuestLogPage from '@/pages/campaign-quest-log/CampaignQuestLog.page';
import { DefaultLayout } from '@/components/layouts/default-layout/DefaultLayout';
import { ForgotPassword } from '@/components/auth/forgot-password/ForgotPassword';
import { AboutUsPage } from '@/pages/about-us/AboutUs.page';
import { ContactUsPage } from '@/pages/contact-us/ContactUs.page';
import { CampaignMapsPage } from '@/pages/campaign-maps/CampaignMaps.page';
import ErrorBoundary from '@/components/error/ErrorBoundary';
import { ProtectedRoute } from '@/components/utils/ProtectedRoute';
import CampaignsListPage from '@/pages/campaigns-home/CampaignListPage';
import { CampaignInvitePage } from '@/pages/campaign-invite-page/CampaignInvite.page';
import { ProtectedLayout } from '@/components/layouts/protected-layout/ProtectedLayout';
import { AuthCallback } from '@/components/auth/auth-callback/AuthCallback';
import { CompleteProfilePage } from '@/pages/complete-profile/CompleteProfile.page';
import { TestPage } from '@/pages/test/TestPage';
import { ContentCreationPage } from '@/pages/campaign-content-creation/CampaignContentCreation.page';
import { ProfilePage } from '@/pages/profile/Profile.page';
import { PricingPage } from '@/pages/pricing/Pricing.page';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <DefaultLayout />
      </ErrorBoundary>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'pricing',
        element: <PricingPage />,
      },
      {
        path: 'about',
        element: <AboutUsPage />,
      },
      {
        path: 'contact',
        element: <ContactUsPage />,
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'test',
        element: <TestPage />,
      },
      {
        path: 'profile',
        element: <ProfilePage />,
      },
      {
        path: 'auth/confirm',
        element: <ConfirmProfilePage />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: 'auth/callback',
        element: <AuthCallback />,
      },
      {
        path: 'complete-profile',
        element: <CompleteProfilePage />,
      },
    ],
  },
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <ProtectedRoute>
          <ProtectedLayout />
        </ProtectedRoute>
      </ErrorBoundary>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: 'campaigns',
        element: <CampaignsListPage openWithCreateModalOpen={false} />,
      },
      {
        path: 'campaigns/new',
        element: <CampaignsListPage openWithCreateModalOpen />,
      },
      {
        path: 'campaign/:campaignId/invite/:inviteId',
        element: <CampaignInvitePage />,
      },
    ],
  },
  {
    path: 'campaign/:campaignId',
    element: (
      <ErrorBoundary>
        <CampaignLayout />
      </ErrorBoundary>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <CampaignHomePage />,
      },
      {
        path: 'quests',
        element: <CampaignQuestLogPage />,
      },
      {
        path: 'content/create',
        element: <ContentCreationPage />,
      },
      {
        path: 'content/:contentId',
        element: <ContentPage />,
      },
      {
        path: 'maps',
        element: <CampaignMapsPage />,
      },
    ],
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
