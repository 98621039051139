import React from 'react';
import { Container, Group, List, Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { IconBrain, IconDice, IconUsers } from '@tabler/icons-react';

export function AboutUsPage() {
  return (
    <Container size="lg">
      <Stack gap="xl" my={50}>
        <Title order={1} ta="center" size="h1">
          About The Campaigner
        </Title>

        <Text size="xl" ta="center">
          We&apos;re the wizards behind the curtain, crafting digital tools for the modern Dungeon
          Master.
        </Text>

        <Group justify="center" gap="xl">
          <Stack align="center" gap="md">
            <ThemeIcon size={60} radius="md" variant="light" color="blue">
              <IconDice size={30} />
            </ThemeIcon>
            <Text fw={700} size="lg">
              Founded in 2024
            </Text>
            <Text ta="center">
              Born from countless TPKs and &quot;forgot my character sheet&quot; moments
            </Text>
          </Stack>

          <Stack align="center" gap="md">
            <ThemeIcon size={60} radius="md" variant="light" color="green">
              <IconUsers size={30} />
            </ThemeIcon>
            <Text fw={700} size="lg">
              Made by DMs, for DMs
            </Text>
            <Text ta="center">We&apos;ve rolled our fair share of natural 1s (and 20s!)</Text>
          </Stack>

          <Stack align="center" gap="md">
            <ThemeIcon size={60} radius="md" variant="light" color="red">
              <IconBrain size={30} />
            </ThemeIcon>
            <Text fw={700} size="lg">
              Constantly Evolving
            </Text>
            <Text ta="center">Like a mimic, but less bitey and more helpful</Text>
          </Stack>
        </Group>

        <Title order={2} ta="center" mt="xl">
          Our Mission
        </Title>
        <Text ta="center" size="lg">
          To vanquish the ancient foes of every Dungeon Master: disorganization, forgetfulness, and
          the dreaded &quot;wait, what happened last session?&quot;
        </Text>

        <Title order={2} ta="center" mt="xl">
          Why Choose The Campaigner?
        </Title>
        <List
          spacing="md"
          size="lg"
          center
          icon={
            <ThemeIcon color="teal" size={24} radius="xl">
              <IconDice size="1rem" />
            </ThemeIcon>
          }
        >
          <List.Item>We speak fluent D&D (and Pathfinder, and Call of Cthulhu, and...)</List.Item>
          <List.Item>Our code is more stable than a Wizards tower (and twice as magical)</List.Item>
          <List.Item>
            We&apos;re always listening to feedback (no Wisdom saving throw required)
          </List.Item>
          <List.Item>
            Our customer support is legendary (like that vorpal sword you&apos;ve been eyeing)
          </List.Item>
        </List>

        <Text ta="center" mt="xl" size="lg" fw={700}>
          Join us in our quest to make campaign management as epic as the stories you tell!
        </Text>
      </Stack>
    </Container>
  );
}
