import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CampaignDTO } from 'campaigner-client';
import { handleApiResponse } from '@/utils/api.utils';
import { campaignApi } from '@/api/apiClient';

export const useCreateCampaign = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ name, description }: { name: string; description: string }) =>
      campaignApi
        .createCampaign({
          name,
          description,
        })
        .then((res) => handleApiResponse<CampaignDTO>(res)),
    onSuccess: (newCampaign) => {
      queryClient.setQueryData(['campaigns', newCampaign.id], newCampaign);
      queryClient.setQueryData<CampaignDTO[]>(['campaigns'], (oldCampaigns) => [
        ...(oldCampaigns || []),
        newCampaign,
      ]);
    },
  });
};
