import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Container, Grid, Group, Text, Title } from '@mantine/core';
import { ContentPreviewDTO } from 'campaigner-client';
import { modals } from '@mantine/modals';
import { useContents } from '@/queries/contentQueries';
import { useCampaign } from '@/queries/campaignQueries';
import { ContentGrid } from '@/components/campaign/content-grid/ContentGrid';
import { useQuestLog } from '@/hooks/useQuestLog';
import { QuestPreview } from '@/components/campaign/quest-preview/QuestPreview';
import { ManageCampaign } from '@/components/campaign/manage-campaign/ManageCampaign';

export default function CampaignHomePage() {
  const { campaignId } = useParams<{ campaignId: string }>();
  const { data: campaign } = useCampaign(campaignId!);
  const [sortOption, setSortOption] = useState('alphabetical');

  const {
    data: contentData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useContents(campaignId!);
  const { questLog } = useQuestLog({ campaignId: campaignId! });

  const sortedContents = useMemo<ContentPreviewDTO[]>(() => {
    if (!contentData) return [];
    const allContents: ContentPreviewDTO[] = contentData.pages.flatMap((page) => page.items);
    const contentFiltered = allContents.filter((c) => c.contentCategory !== 'QUEST');
    const [field, order] = sortOption.split('_');
    return contentFiltered.sort((a, b) => {
      if (sortOption === 'alphabetical') {
        return a.title.localeCompare(b.title);
      }
      const dateA = new Date(a[field as 'createdAt' | 'updatedAt']).getTime();
      const dateB = new Date(b[field as 'createdAt' | 'updatedAt']).getTime();
      return order === 'desc' ? dateB - dateA : dateA - dateB;
    });
  }, [contentData, sortOption]);

  if (!campaignId) {
    return null;
  }

  return (
    <Container size="lg">
      <Group>
        <Title order={1} mb="md">
          {campaign?.name}
        </Title>
        {campaign?.role !== 'ADMIN' && (
          <Button
            onClick={() =>
              modals.open({
                title: '',
                children: <ManageCampaign campaignId={campaignId} baseUrl="" />,
              })
            }
          >
            Manage
          </Button>
        )}
      </Group>
      <Text mb="xl">{campaign?.description}</Text>
      <Grid gutter="md" mb="xl">
        <Grid.Col>
          {questLog && <QuestPreview questLog={questLog} campaignId={campaignId} />}
        </Grid.Col>
        <Grid.Col>
          <ContentGrid
            contents={sortedContents}
            campaignId={campaignId}
            loadMore={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            sortOption={sortOption}
            onSortChange={(value) => setSortOption(value || '')}
          />
        </Grid.Col>
      </Grid>
    </Container>
  );
}
