import React from 'react';
import { Menu, UnstyledButton, Group, Text, rem } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

interface UserMenuProps {
  userName: string;
  onLogout: () => void;
}

export const UserMenu: React.FC<UserMenuProps> = ({ userName, onLogout }) => (
  <Menu position="bottom-end" withArrow>
    <Menu.Target>
      <UnstyledButton>
        <Group gap={7}>
          <Text fw={500} size="sm" lh={1} mr={3}>
            {userName}
          </Text>
          <IconChevronDown style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
        </Group>
      </UnstyledButton>
    </Menu.Target>
    <Menu.Dropdown>
      <Menu.Item component={Link} to="/profile">
        Profile
      </Menu.Item>
      <Menu.Item onClick={onLogout}>Logout</Menu.Item>
    </Menu.Dropdown>
  </Menu>
);
