import { useMutation } from '@tanstack/react-query';
import { CampaignDTORoleEnum, CampaignInviteDTO } from 'campaigner-client';
import { inviteLinkApi } from '@/api/apiClient';
import { handleApiResponse } from '@/utils/api.utils';

export const useProcessInviteLink = () =>
  useMutation({
    mutationFn: async ({
      campaignId,
      inviteLinkId,
    }: {
      campaignId: string;
      inviteLinkId: string;
    }) =>
      inviteLinkApi.processInviteLink(campaignId, {
        inviteLinkId,
      }),
  });

export const useGenerateInviteLink = () =>
  useMutation({
    mutationFn: async ({
      campaignId,
      role,
      durationInDays,
    }: {
      campaignId: string;
      role: CampaignDTORoleEnum;
      durationInDays: number;
    }) =>
      inviteLinkApi
        .generateInviteLink(campaignId, {
          role,
          durationInDays,
        })
        .then((res) => handleApiResponse<CampaignInviteDTO>(res)),
  });
