import { useEffect, useRef } from 'react';
import { SpotlightActionData } from '@mantine/spotlight';
import { useSpotlightStore } from '@/state/spotlightStore';
import { SpotlightManager } from '@/utils/spotlight.utils';

export function useSpotlightManager() {
  const setActions = useSpotlightStore((state) => state.setActions);
  const managerRef = useRef<SpotlightManager | null>(null);

  if (!managerRef.current) {
    managerRef.current = new SpotlightManager(setActions);
  }

  useEffect(
    () => () => {
      // Cleanup all context actions on unmount
      managerRef.current?.removeContextActions([]);
    },
    []
  );

  return {
    addBaseActions: (actions: SpotlightActionData[]) => managerRef.current?.addBaseActions(actions),
    addAuthActions: (actions: SpotlightActionData[], isAuthenticated: boolean) =>
      managerRef.current?.addAuthActions(actions, isAuthenticated),
    addCampaignActions: (actions: SpotlightActionData[]) =>
      managerRef.current?.addCampaignActions(actions),
    addContextActions: (actions: SpotlightActionData[]) =>
      managerRef.current?.addContextActions(actions),
    removeContextActions: (ids: string[]) => managerRef.current?.removeContextActions(ids),
  };
}
