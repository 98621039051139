import React from 'react';
import {
  Badge,
  Button,
  Card,
  Container,
  Group,
  List,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { IconCheck, IconCrown, IconX } from '@tabler/icons-react';

const tiers = [
  {
    name: 'Free',
    price: '$0',
    description: 'For solo adventurers and small parties',
    features: [
      {
        text: 'One campaign',
        included: true,
      },
      {
        text: 'Up to 50 content items',
        included: true,
      },
      {
        text: 'Basic character sheets',
        included: true,
      },
      {
        text: 'Session scheduling',
        included: true,
      },
      {
        text: 'More than one campaign',
        included: false,
      },
      {
        text: 'Unlimited content',
        included: false,
      },
      {
        text: 'Image & Map support',
        included: false,
      },
      {
        text: 'Quest Log',
        included: false,
      },
      {
        text: 'Player access',
        included: false,
      },
      {
        text: 'Custom themes',
        included: false,
      },
      {
        text: 'Advanced dice roller',
        included: false,
      },
      {
        text: 'Initiative tracker',
        included: false,
      },
    ],
  },
  {
    name: 'Pro',
    price: '$5/mo',
    description: 'For serious DMs and their dedicated players',
    features: [
      {
        text: 'Unlimited campaigns',
        included: true,
      },
      {
        text: 'Unlimited content',
        included: true,
      },
      {
        text: 'Advanced character sheets',
        included: true,
      },
      {
        text: 'Session scheduling with reminders',
        included: true,
      },
      {
        text: 'Image & Map support',
        included: true,
      },
      {
        text: 'Quest Log',
        included: true,
      },
      {
        text: 'Player access',
        included: true,
      },
      {
        text: 'Custom themes',
        included: true,
      },
      {
        text: 'Advanced dice roller',
        included: true,
      },
      {
        text: 'Initiative tracker',
        included: true,
      },
      {
        text: 'Early access to new features',
        included: true,
      },
      {
        text: 'Priority support',
        included: true,
      },
    ],
  },
];

export function PricingPage() {
  return (
    <Container size="xl">
      <Stack align="center" gap="xl" my={50}>
        <Title order={1} size={48}>
          Pricing Plans
        </Title>
        <Text size="xl" mb="xl">
          Choose your path to legendary campaign management
        </Text>
        <Badge color="yellow" size="lg" variant="filled">
          Alpha Release
        </Badge>
        <Text size="md" c="dimmed" maw={600}>
          The Campaigner is currently in alpha. All features are subject to change, and we&apos;re
          constantly adding new ones. Your feedback shapes our quest!
        </Text>
      </Stack>

      <Group gap="xl">
        {tiers.map((tier) => (
          <Card key={tier.name} shadow="sm" padding="lg" radius="md" withBorder maw={400} miw={350}>
            <Stack>
              <Group>
                <Title order={2}>{tier.name}</Title>
                <Text size="xl" w={700}>
                  {tier.price}
                </Text>
              </Group>
              <Text size="sm" color="dimmed">
                {tier.description}
              </Text>
              <List
                spacing="xs"
                size="sm"
                center
                icon={
                  <ThemeIcon color="teal" size={24} radius="xl">
                    <IconCheck size="1rem" />
                  </ThemeIcon>
                }
              >
                {tier.features.map((feature, index) => (
                  <List.Item
                    key={index}
                    icon={
                      feature.included ? (
                        <ThemeIcon color="teal" size={24} radius="xl">
                          <IconCheck size="1rem" />
                        </ThemeIcon>
                      ) : (
                        <ThemeIcon color="red" size={24} radius="xl">
                          <IconX size="1rem" />
                        </ThemeIcon>
                      )
                    }
                  >
                    {feature.text}
                  </List.Item>
                ))}
              </List>
              <Button
                fullWidth
                color={tier.name === 'Pro' ? 'green' : 'blue'}
                disabled={tier.name === 'Pro'}
              >
                {tier.name === 'Free' ? 'Start for Free' : 'Coming soon...'}
              </Button>
            </Stack>
          </Card>
        ))}
      </Group>

      <Stack align="center" mt={50} gap="md">
        <ThemeIcon size={50} radius="xl" variant="light" color="yellow">
          <IconCrown size={30} />
        </ThemeIcon>
        <Title order={3}>Why Go Pro?</Title>
        <Text maw={600}>
          The Pro tier isn&apos;t just about managing more campaigns. It&apos;s about elevating your
          entire role-playing experience. Share with your players, customize your virtual tabletop,
          and get early access to features that will make your games truly legendary.
        </Text>
        <Text size="sm" c="dimmed">
          Pro subscriptions help us keep the tavern lights on and the dev team caffeinated. Your
          support fuels our quest to make The Campaigner even more awesome!
        </Text>
      </Stack>
    </Container>
  );
}
