import React from 'react';
import { useForm } from '@mantine/form';
import { Button, Paper, PaperProps, Stack, Text, TextInput } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/api/supabaseClient';

export function ForgotPassword(props: PaperProps) {
  const navigate = useNavigate();
  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      email: '',
    },
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    supabase.auth
      .resetPasswordForEmail(form.values.email)
      .then(() => navigate('/'))
      .catch((err) => console.log(err));
  };

  return (
    <Paper radius="md" p="xl" withBorder {...props}>
      <Text size="lg" fw={500}>
        Forgot Password
      </Text>
      <form onSubmit={handleSubmit}>
        <Stack>
          <TextInput
            required
            label="Email"
            placeholder="your@email.com"
            {...form.getInputProps('email')}
          />
          <Button type="submit" radius="xl">
            Reset Password
          </Button>
        </Stack>
      </form>
    </Paper>
  );
}
