import React from 'react';
import { Group, Stack } from '@mantine/core';
import { IconHome2, IconMap, IconNotebook, IconSearch, IconUserCode } from '@tabler/icons-react';
import { ContentPreviewDTO } from 'campaigner-client';
import { NavItem } from '@/components/campaign/app-shell/nav-item/NavItem';

interface NavContentProps {
  campaignId: string;
  mapPreviews: ContentPreviewDTO[];
  textContentPreviews: ContentPreviewDTO[];
  expanded: boolean;
  isMobileDrawer?: boolean;
  onNavigate: (path: string) => void;
  onSearch: () => void;
  onMobileNavClose: () => void;
}

export function NavContent({
  campaignId,
  mapPreviews,
  textContentPreviews,
  expanded,
  isMobileDrawer = false,
  onNavigate,
  onSearch,
  onMobileNavClose,
}: NavContentProps) {
  return (
    <Stack justify="space-between" h="100%">
      <Stack>
        <Group gap="lg">
          <NavItem
            icon={<IconHome2 size={20} stroke={1.5} />}
            label="Home"
            onClick={() => onNavigate(`/campaign/${campaignId}`)}
            expanded={expanded}
            onMobileNavClose={onMobileNavClose}
          />
          <NavItem
            icon={<IconNotebook size={20} stroke={1.5} />}
            label="Quests"
            onClick={() => onNavigate(`/campaign/${campaignId}/quests`)}
            expanded={expanded}
            onMobileNavClose={onMobileNavClose}
          />
          <NavItem
            icon={<IconMap size={20} stroke={1.5} />}
            label="Maps"
            onClick={() => onNavigate(`/campaign/${campaignId}/maps`)}
            subItems={mapPreviews.map((map) => ({
              id: map.id,
              title: map.title,
              onClick: () => onNavigate(`/campaign/${campaignId}/content/${map.id}`),
            }))}
            expanded={expanded}
            onMobileNavClose={onMobileNavClose}
          />
          <NavItem
            icon={<IconUserCode size={20} stroke={1.5} />}
            label="Content"
            onClick={() => onNavigate('/campaigns')}
            subItems={textContentPreviews.map((content) => ({
              id: content.id,
              title: content.title,
              onClick: () => onNavigate(`/campaign/${campaignId}/content/${content.id}`),
            }))}
            expanded={expanded}
            onMobileNavClose={onMobileNavClose}
          />
        </Group>
      </Stack>
      {!isMobileDrawer && (
        <Group gap="md">
          <NavItem
            icon={<IconSearch size={20} stroke={1.5} />}
            label="Summon Menu"
            onClick={onSearch}
            expanded={expanded}
            onMobileNavClose={() => {
              onMobileNavClose();
              onSearch();
            }}
          />
        </Group>
      )}
    </Stack>
  );
}
