import React from 'react';
import { AppShell, Drawer, Text } from '@mantine/core';
import { useSwipeable } from 'react-swipeable';
import { CampaignDTO, ContentPreviewDTO } from 'campaigner-client';
import { NavContent } from './NavContent';
import { AppHeader } from './AppHeader';
import { useCampaignShell } from '@/hooks/useCampaignShell';

interface MobileCampaignAppShellProps {
  campaign: CampaignDTO;
  contentPreviews: ContentPreviewDTO[];
  children: React.ReactNode;
}

export function MobileCampaignAppShell({
  campaign,
  contentPreviews,
  children,
}: MobileCampaignAppShellProps) {
  const {
    profile,
    mobileOpened,
    setMobileOpened,
    mapPreviews,
    textContentPreviews,
    handleLogout,
    handleSearch,
    navigateTo,
  } = useCampaignShell(campaign, contentPreviews);

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => setMobileOpened(true),
    trackMouse: true,
  });

  const drawerSwipeHandlers = useSwipeable({
    onSwipedLeft: () => setMobileOpened(false),
    trackMouse: true,
  });

  return (
    <AppShell header={{ height: 80 }} withBorder={false} padding="md">
      <AppShell.Header>
        <AppHeader
          isMobile
          mobileOpened={mobileOpened}
          setMobileOpened={setMobileOpened}
          setDesktopExpanded={() => {}}
          campaignName={campaign.name}
          userName={profile?.name || ''}
          onLogout={handleLogout}
        />
      </AppShell.Header>

      <Drawer
        opened={mobileOpened}
        onClose={() => setMobileOpened(false)}
        size="xs"
        title={
          <Text size="lg" fw={700}>
            {campaign.name}
          </Text>
        }
        withCloseButton
        style={{
          position: 'relative',
        }}
      >
        <div {...drawerSwipeHandlers}>
          <NavContent
            campaignId={campaign.id}
            mapPreviews={mapPreviews}
            textContentPreviews={textContentPreviews}
            expanded
            isMobileDrawer
            onNavigate={navigateTo}
            onSearch={handleSearch}
            onMobileNavClose={() => setMobileOpened(false)}
          />
        </div>
      </Drawer>

      <AppShell.Main>
        <div {...swipeHandlers}>{children}</div>
      </AppShell.Main>
    </AppShell>
  );
}
