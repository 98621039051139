import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Container, Group, Text, Title } from '@mantine/core';
import { useLimitedCampaign } from '@/queries/campaignQueries';
import { useProcessInviteLink } from '@/queries/campaignInviteMutations';
import { useAuth } from '@/hooks/useAuth';

export function CampaignInvitePage() {
  const { campaignId, inviteId } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);

  const {
    data: campaign,
    isLoading: campaignLoading,
    error: campaignError,
  } = useLimitedCampaign(campaignId!, inviteId!);

  const { mutateAsync: processInvite } = useProcessInviteLink();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated]);

  const handleJoinCampaign = async () => {
    if (!isAuthenticated || !campaignId || !inviteId) return;

    setIsProcessing(true);
    try {
      await processInvite({
        campaignId,
        inviteLinkId: inviteId,
      });
      navigate(`/campaign/${campaignId}`);
    } catch (error) {
      console.error('Error joining campaign:', error);
      // Handle error (show error message to user)
    } finally {
      setIsProcessing(false);
    }
  };

  if (campaignLoading) return <Text>Loading campaign details...</Text>;
  if (campaignError) return <Text>Error loading campaign: {campaignError.message}</Text>;

  return (
    <Container size="sm">
      <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Title order={2} mb="md">
          Join Campaign
        </Title>
        <Text size="lg" mb="xl">
          Youve been invited to join the campaign:
        </Text>
        <Group p="apart" mb="xl">
          <Text w={500} size="xl">
            {campaign?.name}
          </Text>
          <Text color="dimmed">{campaign?.description}</Text>
        </Group>
        <Button
          fullWidth
          size="lg"
          onClick={handleJoinCampaign}
          loading={isProcessing}
          disabled={!isAuthenticated || isProcessing}
        >
          Join Campaign
        </Button>
      </Card>
    </Container>
  );
}
