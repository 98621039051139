import React from 'react';
import { AppShell, rem } from '@mantine/core';
import { CampaignDTO, ContentPreviewDTO } from 'campaigner-client';
import { NavContent } from './NavContent';
import { AppHeader } from './AppHeader';
import { useCampaignShell } from '@/hooks/useCampaignShell';

interface DesktopCampaignAppShellProps {
  campaign: CampaignDTO;
  contentPreviews: ContentPreviewDTO[];
  children: React.ReactNode;
}

const ICON_WIDTH = rem(80);
const EXPANDED_WIDTH = rem(340);

export function DesktopCampaignAppShell({
  campaign,
  contentPreviews,
  children,
}: DesktopCampaignAppShellProps) {
  const {
    profile,
    desktopExpanded,
    setDesktopExpanded,
    mapPreviews,
    textContentPreviews,
    handleLogout,
    handleSearch,
    navigateTo,
  } = useCampaignShell(campaign, contentPreviews);

  return (
    <AppShell
      header={{ height: 80 }}
      withBorder={false}
      navbar={{
        width: desktopExpanded ? EXPANDED_WIDTH : ICON_WIDTH,
        breakpoint: 'sm',
        collapsed: {
          desktop: false,
          mobile: true,
        },
      }}
      padding="md"
    >
      <AppShell.Header>
        <AppHeader
          isMobile={false}
          mobileOpened={false}
          setMobileOpened={() => {}}
          setDesktopExpanded={setDesktopExpanded}
          campaignName={campaign.name}
          userName={profile?.name || ''}
          onLogout={handleLogout}
        />
      </AppShell.Header>

      <AppShell.Navbar
        withBorder
        p="md"
        onMouseEnter={() => setDesktopExpanded(true)}
        onMouseLeave={() => setDesktopExpanded(false)}
        style={{ transition: 'width 200ms ease' }}
      >
        <NavContent
          campaignId={campaign.id}
          mapPreviews={mapPreviews}
          textContentPreviews={textContentPreviews}
          expanded={desktopExpanded}
          onNavigate={navigateTo}
          onSearch={handleSearch}
          onMobileNavClose={() => {}}
        />
      </AppShell.Navbar>

      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  );
}
