import React from 'react';
import { CampaignDTO, ContentPreviewDTO } from 'campaigner-client';
import { MobileCampaignAppShell } from './MobileCampaignAppShell';
import { DesktopCampaignAppShell } from './DesktopCampaignAppShell';
import { useCampaignShell } from '@/hooks/useCampaignShell';

interface CampaignAppShellProps {
  campaign: CampaignDTO;
  contentPreviews: ContentPreviewDTO[];
  children: React.ReactNode;
}

export function CampaignAppShell({ campaign, contentPreviews, children }: CampaignAppShellProps) {
  const { isMobile } = useCampaignShell(campaign, contentPreviews);

  return isMobile ? (
    <MobileCampaignAppShell campaign={campaign} contentPreviews={contentPreviews}>
      {children}
    </MobileCampaignAppShell>
  ) : (
    <DesktopCampaignAppShell campaign={campaign} contentPreviews={contentPreviews}>
      {children}
    </DesktopCampaignAppShell>
  );
}
