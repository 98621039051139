import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ContentDTO, ContentPreviewDTO, ContentRequest } from 'campaigner-client';
import { handleApiResponse } from '@/utils/api.utils';
import { contentApi } from '@/api/apiClient';

export const useCreateContent = (campaignId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newContent: ContentRequest) =>
      contentApi
        .createContent(campaignId, newContent)
        .then((res) => handleApiResponse<ContentDTO>(res)),

    onSuccess: (newContent) => {
      queryClient.setQueryData<ContentDTO>(
        ['campaigns', campaignId, 'content', newContent.id],
        newContent
      );

      queryClient.setQueryData(['campaigns', campaignId, 'content'], (oldData: any) => {
        if (!oldData) return oldData;

        const newPreview: ContentPreviewDTO = {
          campaignId,
          id: newContent.id,
          title: newContent.title,
          contentCategory: newContent.contentCategory,
          metadata: newContent.metadata,
          updatedAt: newContent.updatedAt,
          createdAt: newContent.createdAt,
        };

        const newPages = oldData.pages.map((page: any, index: number) => {
          if (index === 0) {
            return {
              ...page,
              items: [newPreview, ...page.items],
            };
          }
          return page;
        });

        return {
          ...oldData,
          pages: newPages,
        };
      });
    },
  });
};
