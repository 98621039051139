import React from 'react';
import { Badge, Button, Container, Group, MantineColor, Menu, Stack, Title } from '@mantine/core';
import { IconDotsVertical, IconEdit, IconTrash } from '@tabler/icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { CampaignDTORoleEnum, ContentDTO } from 'campaigner-client';
import { DefaultMantineColor } from '@mantine/core/lib/core/MantineProvider/theme.types';
import MapViewer from '@/components/utils/map-viewer/MapViewer';
import MarkdownRenderer from '@/components/utils/markdown-renderer/MarkdownRenderer';
import ImageViewer from '@/components/utils/image-viewer/ImageViewer';
import { useDeleteContent } from '@/queries/content/mutations/delete.content.mutation';

export interface ContentViewerProps {
  content: ContentDTO;
  setIsEditing: (isEditing: boolean) => void;
  role?: CampaignDTORoleEnum;
}

export function ContentViewer({ content, setIsEditing, role }: ContentViewerProps) {
  const isMobile = useMediaQuery('(max-width: 48em)');
  const navigate = useNavigate();

  const handleEdit = () => {
    setIsEditing(true);
  };

  const { mutateAsync: deleteContent } = useDeleteContent(content.campaignId);
  const handleDelete = async () => {
    //TODO: Add confirm dialogue with loader.
    await deleteContent(content.id);
    navigate(`/campaign/${content.campaignId}`);
  };

  let contentComponent;
  if (content.contentCategory === 'PICTURE') {
    contentComponent = <ImageViewer url={content.url} />;
  } else if (content.contentCategory === 'MAP') {
    contentComponent = <MapViewer url={content.url} />;
  } else {
    contentComponent = <MarkdownRenderer content={content.content} />;
  }

  const ActionsMenu = () => (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        {isMobile ? (
          <Button variant="subtle" size="sm" color="brand-orange" p={0}>
            <IconDotsVertical size={24} />
          </Button>
        ) : (
          <Button color="brand-orange">Actions</Button>
        )}
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Content</Menu.Label>
        {content.contentCategory !== 'MAP' && content.contentCategory !== 'PICTURE' && (
          <Menu.Item leftSection={<IconEdit size={14} />} onClick={handleEdit}>
            Edit
          </Menu.Item>
        )}
        <Menu.Item
          disabled={role !== 'ADMIN'}
          leftSection={<IconTrash size={14} />}
          onClick={handleDelete}
          color="red"
        >
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );

  const getCategoryColor = (category: string): MantineColor => {
    const colorMap: { [key: string]: DefaultMantineColor } = {
      LORE: 'blue',
      QUEST: 'green',
      CHARACTER: 'yellow',
      ITEM: 'orange',
      TIMELINE: 'grape',
      SESSION_NOTES: 'cyan',
      PICTURE: 'pink',
      MAP: 'red',
    };
    return (colorMap[category] || ('gray' as DefaultMantineColor)) as MantineColor;
  };

  return (
    <Container size="lg" p={isMobile ? 'xs' : 'md'}>
      <Stack gap="md">
        <Group justify="space-between" align="center" wrap="nowrap">
          <Stack gap="xs">
            <Title
              order={1}
              style={{
                wordBreak: 'break-word',
                flex: 1,
              }}
            >
              {content?.title}
            </Title>
            <Badge color={getCategoryColor(content.contentCategory)} size="lg">
              {content.contentCategory}
            </Badge>
          </Stack>
          {(role === 'ADMIN' || role === 'AUTHOR') && <ActionsMenu />}
        </Group>
        {contentComponent}
      </Stack>
    </Container>
  );
}
