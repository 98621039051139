import React from 'react';
import { Accordion, Button, Group, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { ArcDTO } from 'campaigner-client';
import { QuestItem } from './QuestItem';

interface ArcItemProps {
  arc: ArcDTO;
  editMode: boolean;
  onAddQuest: () => void;
  onUpdateArc: (arc: ArcDTO) => void;
}

export function ArcItem({ arc, editMode, onAddQuest, onUpdateArc }: ArcItemProps) {
  return (
    <Accordion.Item key={arc.id} value={arc.id || ''}>
      <Accordion.Control>
        <Text fw={700}>{arc.name}</Text>
      </Accordion.Control>
      <Accordion.Panel>
        <Text mb="sm">{arc.description}</Text>
        <Accordion multiple>
          {arc.quests.map((quest) => (
            <QuestItem
              key={quest.id}
              quest={quest}
              editMode={editMode}
              onUpdateQuest={(updatedQuest) => {
                const updatedArc = {
                  ...arc,
                  quests: arc.quests.map((q) => (q.id === updatedQuest.id ? updatedQuest : q)),
                };
                onUpdateArc(updatedArc);
              }}
            />
          ))}
        </Accordion>
        {editMode && (
          <Group mt="sm">
            <Button
              leftSection={<IconPlus size={14} />}
              variant="light"
              size="sm"
              onClick={onAddQuest}
            >
              Add Quest
            </Button>
          </Group>
        )}
      </Accordion.Panel>
    </Accordion.Item>
  );
}
