import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Center, Loader } from '@mantine/core';
import { useAuth } from '@/hooks/useAuth';

export function AuthCallback() {
  const navigate = useNavigate();
  const { isAuthenticated, profile, loading } = useAuth();

  useEffect(() => {
    if (!loading) {
      if (isAuthenticated) {
        if (profile) {
          navigate('/campaigns');
        } else {
          navigate('/complete-profile');
        }
      } else {
        navigate('/login');
      }
    }
  }, [isAuthenticated, profile, loading, navigate]);

  return (
    <Center style={{ height: '100vh' }}>
      <Loader />
    </Center>
  );
}
