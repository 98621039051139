import React, { useState } from 'react';
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Card,
  Container,
  Flex,
  Group,
  Loader,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { spotlight } from '@mantine/spotlight';
import { CampaignDTO, CampaignDTORoleEnum } from 'campaigner-client';
import { useCampaigns } from '@/queries/campaignQueries';
import { AnimatedActionButton } from '@/components/general/animated-action-button/ActionButton';
import { useCreateCampaign } from '@/queries/campaignMutations';

interface CampaignsListPageProps {
  openWithCreateModalOpen: boolean;
}

function CampaignsListPage({ openWithCreateModalOpen }: CampaignsListPageProps) {
  const { data: campaigns, isLoading: campaignsLoading } = useCampaigns();
  const [createModalOpen, setCreateModalOpen] = useState(openWithCreateModalOpen);
  const [newCampaign, setNewCampaign] = useState<Partial<CampaignDTO>>({});
  const createCampaign = useCreateCampaign();

  const handleCreateCampaign = () => {
    console.log('Creating campaign:', newCampaign);
    if (!newCampaign || !newCampaign.name || !newCampaign.description) return;
    createCampaign.mutateAsync({
      name: newCampaign.name,
      description: newCampaign.description,
    });
    setCreateModalOpen(false);
    setNewCampaign({});
  };

  const getRoleBadgeProps = (role: CampaignDTORoleEnum) => {
    switch (role) {
      case 'ADMIN':
        return {
          color: 'accent',
          variant: 'light',
        };
      case 'AUTHOR':
        return {
          color: 'brand-teal',
          variant: 'light',
        };
      case 'MEMBER':
        return {
          color: 'brand-orange',
          variant: 'light',
        };
      default:
        return {
          color: 'gray',
          variant: 'outline',
        };
    }
  };

  return (
    <Container size="lg" pos="relative" pb={60}>
      <Group justify="space-between" mb="xl">
        <Title order={1}>My Campaigns</Title>
        <Button onClick={() => setCreateModalOpen(true)}>Create New Campaign</Button>
      </Group>

      <Stack>
        <LoadingOverlay visible={campaignsLoading} />
        {campaigns?.map((campaign) => (
          <Card
            component={Link}
            to={`/campaign/${campaign.id}`}
            key={campaign.id}
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
          >
            <Flex align="center" justify="space-between">
              <Stack gap="xs">
                <Group>
                  <Text fw={500} size="lg">
                    {campaign.name}
                  </Text>
                  <Badge {...getRoleBadgeProps(campaign.role)}>{campaign.role}</Badge>
                </Group>
                <Text size="sm" c="dimmed">
                  {campaign.description}
                </Text>
              </Stack>
              <ActionIcon variant="subtle" color="gray">
                <IconChevronRight size={16} />
              </ActionIcon>
            </Flex>
          </Card>
        ))}
      </Stack>

      <Modal
        opened={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        title="Create New Campaign"
      >
        <TextInput
          label="Campaign Name"
          placeholder="Enter campaign name"
          value={newCampaign.name || ''}
          onChange={(event) =>
            setNewCampaign({
              ...newCampaign,
              name: event.currentTarget.value,
            })
          }
          mb="md"
        />
        <Textarea
          label="Campaign Description"
          placeholder="Enter campaign description"
          value={newCampaign.description || ''}
          onChange={(event) =>
            setNewCampaign({
              ...newCampaign,
              description: event.currentTarget.value,
            })
          }
          mb="md"
        />
        <Button onClick={handleCreateCampaign} fullWidth>
          Create Campaign
        </Button>
      </Modal>
      <Box pos="fixed" bottom={20} right={20} style={{ zIndex: 1000 }}>
        <AnimatedActionButton onClick={() => spotlight.open()} />
      </Box>
    </Container>
  );
}

export default CampaignsListPage;
