import React from 'react';
import { Accordion, Button, Group, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { QuestDTO, QuestLogDTO } from 'campaigner-client';
import { QuestItem } from './QuestItem';

interface MiscQuestsItemProps {
  miscQuests: QuestLogDTO['miscQuests'];
  editMode: boolean;
  onAddQuest: () => void;
  onUpdateQuest: (updatedQuestLog: QuestDTO) => void;
}

export function MiscQuestsItem({
  miscQuests,
  editMode,
  onAddQuest,
  onUpdateQuest,
}: MiscQuestsItemProps) {
  return (
    <Accordion.Item value="misc">
      <Accordion.Control>
        <Text fw={700}>Miscellaneous Quests</Text>
      </Accordion.Control>
      <Accordion.Panel>
        <Accordion multiple>
          {miscQuests.map((quest) => (
            <QuestItem
              key={quest.id}
              quest={quest}
              editMode={editMode}
              onUpdateQuest={(updatedQuest) => onUpdateQuest(updatedQuest)}
            />
          ))}
        </Accordion>
        {editMode && (
          <Group mt="sm">
            <Button
              leftSection={<IconPlus size={14} />}
              variant="light"
              size="sm"
              onClick={onAddQuest}
            >
              Add Misc Quest
            </Button>
          </Group>
        )}
      </Accordion.Panel>
    </Accordion.Item>
  );
}
