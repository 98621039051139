import React from 'react';
import { Badge, Button, Card, Group, Stack, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { QuestLogDTO } from 'campaigner-client';

interface QuestPreviewProps {
  questLog: QuestLogDTO;
  campaignId: string;
}

export function QuestPreview({ questLog, campaignId }: QuestPreviewProps) {
  const navigate = useNavigate();
  const activeQuests = [
    ...questLog.arcs.flatMap((arc) => arc.quests.filter((quest) => quest.status === 'ACTIVE')),
    ...questLog.miscQuests.filter((quest) => quest.status === 'ACTIVE'),
  ];

  const previewQuests = activeQuests.slice(0, 3);

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Stack>
        <Group>
          <Text size="xl">Active Quests</Text>
          <Button onClick={() => navigate(`/campaign/${campaignId}/quests`)} variant="light">
            View All Quests
          </Button>
        </Group>
        {previewQuests.map((quest) => (
          <Card key={quest.id} padding="sm" radius="sm" withBorder>
            <Text>{quest.name}</Text>
            <Text size="sm" color="dimmed" lineClamp={2}>
              {quest.description}
            </Text>
            <Group mt="xs">
              <Badge color="blue" variant="light">
                {quest.objectives.filter((obj) => obj.completed).length} / {quest.objectives.length}{' '}
                objectives
              </Badge>
            </Group>
          </Card>
        ))}
        {activeQuests.length > 3 && (
          <Text size="sm" color="dimmed">
            And {activeQuests.length - 3} more active quests...
          </Text>
        )}
      </Stack>
    </Card>
  );
}
