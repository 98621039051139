import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ContentDTO } from 'campaigner-client';
import { contentApi } from '@/api/apiClient';

export const useDeleteContent = (campaignId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (contentId: string) => contentApi.deleteContent(campaignId, contentId),
    onSuccess: (_, deletedContentId) => {
      queryClient.setQueryData<ContentDTO[]>(
        ['campaigns', campaignId, 'content', { id: 'all' }],
        (oldData) => oldData?.filter((content) => content.id !== deletedContentId) ?? []
      );
      queryClient.removeQueries({
        queryKey: ['campaigns', campaignId, 'content', deletedContentId],
      });
      queryClient.invalidateQueries({ queryKey: ['campaigns', campaignId, 'content'] });
    },
  });
};
