import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkToc from 'remark-toc';
import remarkGfm from 'remark-gfm';

interface MarkdownRendererProps {
  content: string;
}

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ content }) => (
  <div className="prose">
    <ReactMarkdown remarkPlugins={[remarkToc, remarkGfm]}>{content}</ReactMarkdown>
  </div>
);

export default MarkdownRenderer;
