import React from 'react';
import { ActionIcon, Checkbox, Group, Timeline } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ObjectiveDTO } from 'campaigner-client';

interface ObjectiveItemProps {
  objective: ObjectiveDTO;
  editMode: boolean;
  onUpdateObjective: (updatedObjective: ObjectiveDTO) => void;
}

export function ObjectiveItem({ objective, editMode, onUpdateObjective }: ObjectiveItemProps) {
  return (
    <Timeline.Item
      bullet={
        <Checkbox
          checked={objective.completed}
          onChange={(e) =>
            onUpdateObjective({
              ...objective,
              completed: e.currentTarget.checked,
            })
          }
          disabled={!editMode}
        />
      }
      title={objective.description}
    >
      {editMode && (
        <Group mt="xs">
          <ActionIcon
            size="sm"
            color="blue"
            onClick={() => {
              /* Edit objective */
            }}
          >
            <IconEdit size={16} />
          </ActionIcon>
          <ActionIcon
            size="sm"
            color="red"
            onClick={() => {
              /* Delete objective */
            }}
          >
            <IconTrash size={16} />
          </ActionIcon>
        </Group>
      )}
    </Timeline.Item>
  );
}
